/// <reference types="cordova-plugin-statusbar" />
/// <reference types="cordova-plugin-device" />
/// <reference types="cordova-plugin-dialogs" />
/// <reference types="cordova-plugin-splashscreen" />

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/browser';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// if (localStorage.startPause) {
// if (true) {
//   alert('pause on start. Connect your debugger');
// }

// global object handy for exposing debugging methods/data
window.sst = {};

handleDarkMode();

const boot = () => {
  console.log('booting app');
  try {
    if (environment.production) {
      enableProdMode();
    }
    platformBrowserDynamic().bootstrapModule(AppModule);
  } catch (e) {
    console.error('exception booting application', e);
    Sentry.captureException(e);
  }
};

/*
*  If cordova is present, wait for it to initialize, otherwise just try to
*  bootstrap the application.
*/

if (typeof window['cordova'] !== 'undefined') {
  console.log('cordova case, waiting for deviceready');
  document.addEventListener('deviceready', boot);
} else {
  boot();
}

function handleDarkMode() {
  const usesDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches || false;
  const manifests =
    document.querySelectorAll('link [rel=manifest]');

  const env = {
    dev: 'int',
    int: 'int',
    stg: 'stg',
    prod: 'prd',
    prd: 'prd'
  }[environment.envName] || 'prd';

  function switchManifest(usesDarkMode) {
    // update both of them, as it's unclear which one might be active at startup.
    manifests.forEach((m: HTMLLinkElement) => {
      if (usesDarkMode) {
        m.href = `./assets/icons/${env}/manifest-dark.json`;
      } else {
        m.href = `./assets/icons/${env}/manifest.json`;
      }
    });
  }

  window.matchMedia('(prefers-color-scheme: dark)')
    .addEventListener('change', e => switchManifest(e.matches));
  switchManifest(usesDarkMode);
}
