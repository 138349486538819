import { EventEmitter, Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import * as Debug from 'debug';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Config } from '../framework/config';
import { PreferencesService } from '../services/preferences.service';
import { UIStateService } from '../services/uiState.service';
import { SessionEventService } from '../sessionEvents/sessionEvent.service';

declare const SafariViewController;
const debug = Debug('sst:cordova.service');

declare const device: any;
declare const plugins: any;
declare const NavigationBar: any;

const receivedCustomURL = new EventEmitter<string>();

export function getReceivedCustomURL(): EventEmitter<string> {
  return receivedCustomURL;
}

export function openBrowserTab(url: string, hidden = false) {
  // Despite the name, this also works on Android and uses CustomTabs
  // See https://github.com/EddyVerbruggen/cordova-plugin-safariviewcontroller/wiki
  SafariViewController.isAvailable(available => {
    if (available) {
      // NB: `hidden: true` does NOT work on Android, the browser tab will be visible
      SafariViewController.show({ url, hidden, animated: false });
    } else {
      window.open(url, '_system');
    }
  });
}

export function closeBrowserTab() {
  SafariViewController.hide();
}
@Injectable({
  providedIn: 'root'
})
export class CordovaService {
  public isCordova = false;
  public inBackground = false;
  public isInBackground$ = new BehaviorSubject<boolean>(false);
  public didResume = false;
  public keyboardDidShow = new EventEmitter();
  public keyboardDidHide = new EventEmitter();
  private isIos = false;
  private isAndroid = undefined;
  public deviceReady$ = new BehaviorSubject(undefined);
  private keyboard: any;

  constructor(
    private config: Config,
    private uiStateService: UIStateService,
    private sessionEventService: SessionEventService,
    private preferencesService: PreferencesService
  ) {
    if (['android', 'ios'].indexOf(this.config.build.platform) === -1) {
      // not on a cordova platform
      return;
    }
    this.isAndroid = config.build.platform === 'android';
    this.isIos = config.build.platform === 'ios';
    this.isCordova = true;
    this.bindEvents();
  }

  public share(options) {
    return new Promise((resolve, reject) => {
      console.log('sharing options', options);

      // possible workaround for slow launch, based on random comments on github tickets.
      setTimeout(() => {
        plugins.socialsharing.shareWithOptions(options, res => {
          // on success
          console.log('Share incident report success', res);
          this.sessionEventService.addEvent('ui.share-incident-report', res);
          resolve(res);
        }, err => {
          // on error
          console.error('Share Incident report error', err);
          this.sessionEventService.addEvent('ui.share-incident-report-error', err);
          reject(err);
        });
      }, 0);
    });
  }

  private bindEvents = () => {
    // Bind Event Listeners
    //
    // Bind any events that are required on startup. Common events are:
    // 'load', 'deviceready', 'offline', and 'online'.
    // console.log('binding cordova events');
    document.addEventListener('deviceready', this.onDeviceReady, false);
    document.addEventListener('resume', this.onResume, false);
    document.addEventListener('pause', this.onPause, false);
    // document.addEventListener('click', this.onClick, false);
    window.addEventListener('keyboardDidShow', this.onKeyboardShow);
    window.addEventListener('keyboardDidHide', this.onKeyboardHide);
    // Handler for custom URL scheme (com.shotspotter.alerts://)
    (window as any).handleOpenURL = url => {
      debug('handleOpenURL', url);
      receivedCustomURL.emit(url);
    };
  };

  private setStatusBarColors() {
    // console.log('set status bar colors');

    if (typeof StatusBar !== 'undefined') {
      if (this.isAndroid) {
        this.setAndroidColors();
      } else {
        this.setIOSColors();
      }
    }
  }

  private setAndroidColors() {
    const color = '#02101B';
    StatusBar.backgroundColorByHexString(color);
    NavigationBar.backgroundColorByHexString(color);
  }

  private setIOSColors() {
    // ios no longer needs theme or route-dependent styling,
    // because we now have a header,
    // and the header is always the same color, regardless of theme.
    StatusBar.styleLightContent();
  }

  public isDeviceReady = false;
  // deviceready Event Handler
  private onDeviceReady = () => {
    this.isDeviceReady = true;

    this.preferencesService.ready$.pipe(filter(Boolean)).subscribe(() => {
      this.setStatusBarColors();
    });

    if (this.isPhoneSize()) {
      // not needed for iphone (uses separate config.xml setting)
      // but shouldn't be harmful
      if (screen?.orientation['lock']) {
        screen.orientation['lock']('portrait');
      }
    }

    (navigator as any).splashscreen.hide();

    // ['Keyboard'] is a hack to avoid pulling in types for plugin

    this.keyboard = window['Keyboard'];
    if (this.keyboard) {
      if (this.isIos) {
        this.keyboard.hideFormAccessoryBar(true);
      }
    } else {
      const msg = 'cordova-plugin-ionic-keyboard not available';
      console.error(msg);
      Sentry.captureMessage(msg);
    }

    this.deviceReady$.next(true);
  };

  private onResume = () => {
    this.sessionEventService.addEvent('ui.cordova-resume', {
      deviceId: device.uuid
    });
    this.inBackground = false;
    this.isInBackground$.next(false);
    this.didResume = true;
    // didResume only active for 1 second
    setTimeout(() => {
      this.didResume = false;
    }, 1000);
  };

  private onPause = () => {
    this.sessionEventService.addEvent('ui.cordova-pause', {
      deviceId: device.uuid
    });
    this.inBackground = true;
    this.isInBackground$.next(true);
    this.didResume = false;
  };

  private onKeyboardShow = e => {
    // console.log('onKeyboardShow', e.keyboardHeight);
    this.uiStateService.keyboardIsVisible = true;
    this.keyboardDidShow.emit(e);
  };

  private onKeyboardHide = e => {
    this.uiStateService.keyboardIsVisible = false;
    this.keyboardDidHide.emit(e);
  };

  private isPhoneSize() {
    const threshold = 736;
    return window.innerHeight <= threshold || window.innerWidth <= threshold;
  }

  public setKeyboardVisible(visible: boolean) {
    if (visible) {
      this.keyboard?.show();
    } else {
      this.keyboard?.hide();
    }
  }
}
