import Debug from 'debug';
import build from '../build.json';

// Some build info (platform) is inferred at runtime so this file
// needs to be imported instead of the static build.json wherever
// build information is used.

const debug = Debug('sst:build-info.ts');

type BuildInfo = typeof build & {
    // see preload.js in electron
    electronBuild?: {
        appId: string;
        name: string;
        description: string;
        version: string;
        build?: string;
        commitHash?: string;
        url: string;
    };
};

const buildInfo: BuildInfo = { ...build };

if (window.sstElectronApi) {
  debug('running in electron context');
  const electronBuild = window.sstElectronApi.build;
  debug('electron build', electronBuild);
  buildInfo.platform = 'electron';
  buildInfo.electronBuild = electronBuild;
}

export default buildInfo;
