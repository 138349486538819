import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';

import { UtilService } from '../framework/dom.util';
import { LocationSource } from '../location/location-source';
import { IFilterOption } from '../main/incident-filter/IFilterOption';
import { Incident } from '../models/incident.models';
import { DrawerState } from './DrawerState';
import { Organization } from './organization';
import Debug from 'debug';

const debug = Debug('sst:uiState.service');
@Injectable({
  providedIn: 'root'
})
export class UIStateService {
  public isSignedIn = false;
  public showGroundTruth = false;
  public supportEmail = 'support@soundthinking.com';
  public keyboardIsVisible = false;
  private _drawerAnimationSpeed = 100;
  // public DRAWER_ANIMATION_SPEED = 550;
  public get DRAWER_ANIMATION_SPEED() {
    return this._drawerAnimationSpeed;
  }
  public set DRAWER_ANIMATION_SPEED(val) {
    this._drawerAnimationSpeed = val;
    window.document.body.style
      .setProperty('--drawer-animation-speed', `${val}ms`);
  }
  private _notificationAnimationSpeed = 500;
  public get notificationAnimationSpeed() {
    return this._notificationAnimationSpeed;
  }
  public set notificationAnimationSpeed(val) {
    this._notificationAnimationSpeed = val;
    window.document.body.style
      .setProperty('--notification-animation-speed', `${val}ms`);
  }
  public didLongPress = false;
  public selectedInterval = 24 * 1000 * 60 ** 2;
  public timerIsVisible = false;
  public isInLandscapeMobileMode = false;
  public systemMenuDidAnimate = new Subject<boolean>();
  public drawerStateChanged = new Subject<DrawerState>();
  public drawerToggled = new Subject<boolean>();
  public streetViewStateChanged = new Subject<boolean>();
  public map$ = new BehaviorSubject<google.maps.Map>(undefined);
  public mapDestroyed$ = new Subject();
  public selectedIncident$ = new BehaviorSubject<Incident>(undefined);

  // note: replaySubject used here avoids the initial []
  // so we can trust that the initial value received is the value
  // from the server (even if [])
  public incidents$ = new ReplaySubject<Incident[]>();
  public incidentUpdated$ = new Subject<Incident>();
  public savedSubscriptions$ = new Subject();
  public openIncidentPopup$ = new Subject<Incident>();
  public openIncidentPopup?: Incident;
  public displayShotLocations$ = new BehaviorSubject<boolean>(false);
  public focusMapSearch$ = new Subject<boolean>();

  public incidentDetail: any = {
    report: {}
  };

  public adminMessages: any = {};

  public deviceLocation: any;

  public get streetViewVisible() {
    return this._streetViewVisible;
  }
  public set streetViewVisible(value) {
    if (value !== this._streetViewVisible) {
      this._streetViewVisible = value;
      this.streetViewStateChanged.next(value);
    }
  }

  public ignoreSystemMenuClickAway = false;
  public systemPaneOpen$ = new BehaviorSubject<string>('');
  public get systemPaneOpen() {
    return this._systemPaneOpen;
  }
  public set systemPaneOpen(pane: string) {
    this._systemPaneOpen = pane;
    this.systemPaneOpen$.next(pane);
  }

  private _systemPaneOpen = '';
  private _streetViewVisible = false;
  private _systemMenuIsOpen = false;
  private drawerWasOpen: boolean = undefined;
  private _drawerState: DrawerState = DrawerState.List;
  private _drawerIsOpen = !UtilService.isMobile;
  private systemMenuIsAnimating;

  public incidentFilterOpen$ = new BehaviorSubject<boolean>(true);
  public selectedFilterOption$ = new BehaviorSubject<IFilterOption>(undefined);
  public org$ = new BehaviorSubject<Organization | undefined>(undefined);
  public sharedDevice$ = new BehaviorSubject<LocationSource | undefined>(undefined);
  public currentDevice$ = new BehaviorSubject<LocationSource | undefined>(undefined);
  public mapSearchOpen$ = new BehaviorSubject<boolean>(true);
  public loadingIncidents = true;
  public searchText: string;
  public isReloading = false;


  public get systemMenuIsOpen() {
    return this._systemMenuIsOpen;
  }
  public set systemMenuIsOpen(val) {
    debug('systemMenuIsOpen', val);
    if (this.systemMenuIsAnimating || this._systemMenuIsOpen === val) {
      return;
    }
    this.systemMenuIsAnimating = true;
    setTimeout(() => {
      this.systemMenuIsAnimating = false;
    }, this.DRAWER_ANIMATION_SPEED);

    this._systemMenuIsOpen = val;
    if (!val) {
      // close the current pane, but not instantly
      // to avoid seeing transition while pane is closing
      setTimeout(() => {
        this.systemPaneOpen = '';
        this.systemMenuDidAnimate.next(val);
      }, this.DRAWER_ANIMATION_SPEED);
    }

    // Adjust right padding for Intercom (applies to web/electron only)
    // A CSS transition is specified in common.scss to animate this at
    // the same speed as the system panel transition.
    if (window['intercomSettings']) {
      window['intercomSettings'].horizontal_padding = val ? 497 : 80;
      if (window['Intercom']) {
        window['Intercom']('update');
      }
    }

    if (this._systemMenuIsOpen) {
      // add softclose handlers.
      addEventListener('mousedown', this.onWindowClicked);
      addEventListener('touchstart', this.onWindowClicked);
    } else {
      // remove softclose handlers.
      removeEventListener('mousedown', this.onWindowClicked);
      removeEventListener('touchstart', this.onWindowClicked);
    }
  }
  public get drawerIsOpen(): boolean {
    return this._drawerIsOpen;
  }
  public set drawerIsOpen(value: boolean) {
    const state = this._drawerIsOpen;
    this._drawerIsOpen = value;
    if (state !== value) {
      this.drawerToggled.next(value);
    }
  }
  public placeSearchResult$ =
    new BehaviorSubject<google.maps.places.PlaceResult>(undefined);

  constructor() {
    // this sets css variable too
    this.DRAWER_ANIMATION_SPEED = 0;
    this.notificationAnimationSpeed = 500;
  }

  public toggleDrawer(toOpen?: boolean): Promise<void> {
    const beforeChange = this.drawerIsOpen;
    if (toOpen === undefined) {
      this.drawerIsOpen = !this.drawerIsOpen;
    } else {
      this.drawerIsOpen = toOpen;
    }

    const drawerToggled = beforeChange !== this.drawerIsOpen;

    if (!drawerToggled) {
      return Promise.resolve();
    }

    return new Promise<void>(resolve => {
      setTimeout(() => {
        resolve();
      }, this.DRAWER_ANIMATION_SPEED);
    });
  }

  public toggleLandscapeClass(isActive) {
    this.isInLandscapeMobileMode = isActive;
  }

  public saveDrawerPosition(): void {
    this.drawerWasOpen = this.drawerIsOpen;
  }

  public restoreDrawerPosition(): void {
    if (this.drawerWasOpen !== undefined) {
      this.toggleDrawer(this.drawerWasOpen);
      this.drawerWasOpen = undefined;
    }
  }

  public get drawerState() {
    return this._drawerState;
  }

  public set drawerState(newState: DrawerState) {
    if (this._drawerState !== newState) {
      // console.log('changing drawer state', newState);
      this._drawerState = newState;
      this.drawerStateChanged.next(newState);
    }
  }

  private onWindowClicked = (ev: Event) => {
    if (this.ignoreSystemMenuClickAway) {
      return;
    }
    if (UtilService.getClosest(ev.target, 'app-messages')) {
      return;
    }

    if (!UtilService.getClosest(ev.target, 'app-system-panel')) {
      ev.stopPropagation();
      ev.preventDefault();
      this.systemMenuIsOpen = false;
    }
  };
} // class

