import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, Router } from '@angular/router';
import * as Debug from 'debug';
import { Subscription } from 'rxjs';
import { SessionEventService } from './sessionEvents/sessionEvent.service';
import { TranslateService } from '@tolgee/ngx';
import moment from 'moment';
import 'moment/min/locales';

const debug = Debug('sst:index.component');
@Component({
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class]': 'bodyClass'
  },
  selector: 'body',
  templateUrl: 'index.component.html'
})
export class IndexComponent implements OnInit, OnDestroy {
  public bodyClass = '';
  private subscriptions = new Subscription();

  constructor(
    private router: Router,
    private sessionEventService: SessionEventService,
    public translateService: TranslateService
  ) {
  }

  public ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  public ngOnInit() {
    this.subscriptions.add(this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.sessionEventService.addEvent('ui.router-navigation-end', {
          url: e.urlAfterRedirects
        });

        // note: urlAfterRedirects rather than url is important for default case of '/' route
        // which redirects to /main. need to result in 'main', not ''.
        this.bodyClass = (e.urlAfterRedirects.split('/')[1] || '').split('?')[0];
        return;
      }
      if (e instanceof NavigationCancel) {
        debug('navigation cancelled', e.reason);
      }
      if (e instanceof NavigationError) {
        // console.error('navigation Error', e);
        return;
      }
    }));

    this.subscriptions.add(this.translateService.languageAsync.subscribe(lang => {
      moment.locale(lang);
    }));

    this.subscriptions.add(this.translateService
      .translate('cordova-login-overlay-message', 'Click or tap anywhere to sign in.')
      .subscribe(v => {
        // the #cordova-login-overlay element may not exist yet
        // so just attach this to it's parent element.
        const r: HTMLElement = document.querySelector('body');
        if (r) {
          r.style.setProperty('--cordova-login-overlay-message', `'${v}'`);
        }
      }));
  }
}
