import { Injectable } from '@angular/core';
import * as Debug from 'debug';
import moment from 'moment-timezone';
import { Subject } from 'rxjs';

import { DateService } from '../services/date.service';
import { OfflineStatusService } from './../services/offline-status.service';
import { SessionEvent } from './sessionEvent';

const debug = Debug('sst:sessionEvent.service');

@Injectable({
  providedIn: 'root'
})
export class SessionEventService {
  public syncEvent = new Subject();
  public incidentId: string;
  public syncEnabled = false;
  private isEnabled = false;
  private events = [];
  private retryTimer?: number;

  constructor(
    private dateService: DateService,
    private offlineStatusService: OfflineStatusService
  ) {
    // placeholder
  }

  public enable(syncEnabled = true) {
    this.syncEnabled = syncEnabled;
    this.isEnabled = true;
  }

  public disable() {
    this.isEnabled = false;
    if (this.events.length > 0) {
      console.log('abandoning session event information', this.events);
    }
    this.events.length = 0; // truncate
    // console.log('sessionEvents disabled');
  }

  public addEvent(name, data) {
    if (!this.isEnabled) {
      return;
    }

    let onlineStatus = 'Unknown';
    if ('isOffline' in this.offlineStatusService) {
      onlineStatus = this.offlineStatusService.isOffline ? 'Offline' : 'Online';
    }

    if (!data.sourceIncidentId) {
      data.sourceIncidentId = this.incidentId;
    }

    const now = this.dateService.getDate();
    const sessionEvent = new SessionEvent(
      name,
      data,
      now.toISOString(),
      onlineStatus,
      moment.tz.guess()
    );

    this.events.push(sessionEvent);
    if (this.syncEnabled) {
      this.sync();
    }
  }

  public restoreEvents(events) {
    this.events.unshift(...events);
    this.sync(); // trigger the sync again
  }

  public sync(attemptNum = 1): Promise<number> {
    return new Promise(resolve => {
      if (this.events.length === 0) {
        // nothing to do
        resolve(0);
        return;
      }

      if (this.offlineStatusService.isOffline) {
        // exponential backoff on retries.
        attemptNum = Math.min(attemptNum, 7); // approx max 1 minute
        const delay = (attemptNum ** 2) * 1000;
        if (this.retryTimer) {
          clearTimeout(this.retryTimer);
        }
        this.retryTimer = window.setTimeout(() => {
          const attempt = attemptNum + 1;
          debug('sync retry attempt', attempt);
          resolve(this.sync(attempt));
        }, delay);
      } else {
        // trigger the sync event
        //
        // use of ... creates a new array
        // with content of existing array
        const eventsToSync = [...this.events];
        // allowing us to truncate this array
        this.events.length = 0;

        // before processing the sync event
        this.syncEvent.next(eventsToSync);
        resolve(eventsToSync.length);
      }
    });
  }
}
