<div class="below-logo version-update">
  <!-- i18n we are intentionally NOT translating this, as any new customer will not be able to execute this code
    and we can make upgrading to the latest windows installer a prerequisite for enabling spanish for an agency.
  -->
  <div class="title text-center" >Software Update Found</div>

  <div class="instructions">
    <p>
      <span >An updated version of the ShotSpotter App is available. Do you want to
      download the update now?</span>
    </p>
    <br />
    <p><span >Selecting YES will automatically download and run the new version.</span></p>
    <p>
      <span>Selecting NO will continue into the application, using the current
      version.</span>
    </p>
    <p><span >This check is performed every time you launch the application.</span></p>

    <div class="actions" *ngIf="updateProgress < 0">
      <button class="button primary" (click)="update()">YES, update now</button>
      <button class="button" (click)="proceed()">NO, update later</button>
    </div>

    <div *ngIf="updateProgress >= 0" class="updateProgress">
      <span>Update is in progress</span>: {{ progressWidth }}
      <div class="progressBar" [style.width]="progressWidth"></div>
    </div>

    <div *ngIf="updateProgress >= 100">
      Update completed - the application will restart now.
    </div>

    <div *ngIf="error" class="errorMessage">
      Sorry we encountered an error while updating. Please close and relaunch to
      try again.
    </div>
  </div>
</div>
