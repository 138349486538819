import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@tolgee/ngx';
import { Observable } from 'rxjs';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  public constructor(private translateService: TranslateService) { }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let language = this.translateService.language || 'en';
    if (language !== 'en') {
      const prefix = language.substring(0, 2);
      language = `${language}, ${prefix};q=0.8, en;q=0.7`; // eg 'es-419, es;q=0.8, en;q=0.7'
    }
    const headers = req.headers.set('Accept-Language', language);
    const clonedRequest = req.clone({
      headers
    });

    return next.handle(clonedRequest);
  }
}
