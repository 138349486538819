import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from '../framework/dom.util';
import { VersionUpdateService } from './versionUpdate';

// tslint:disable:max-classes-per-file
class ProgressUpdate {
  public total: number;
  public count: number;
}

@Component({
  selector: 'app-version-update',
  templateUrl: './versionUpdate.component.html'
})
export class VersionUpdateComponent implements OnInit, OnDestroy {
  public updateProgress = -1;
  public progress: ProgressUpdate;
  public error;

  private routeParamsSubscription;

  private updateUi = UtilService.throttle(progress => {
    if (!progress.total) {
      return;
    }
    const updateProgress = Math.round((progress.count / progress.total) * 100);
    if (updateProgress < 1) {
      return;
    }
    this.zone.run(() => {
      this.progress = progress;
      this.updateProgress = updateProgress;
    });
  }, 300); // only update ui every 300ms

  constructor(private titleService: Title,
              private versionUpdateService: VersionUpdateService,
              private zone: NgZone,
              private router: Router,
              private route: ActivatedRoute
  ) {
    this.titleService.setTitle('ShotSpotter - software update');
  }

  public get progressWidth() {
    return this.updateProgress === -1 ? '0%' : this.updateProgress + '%';
  }

  public ngOnDestroy() {
    this.routeParamsSubscription.unsubscribe();
  }

  public ngOnInit() {
    this.routeParamsSubscription = this.route.params.subscribe((params: any) => {
      if (params.force) {
        this.update();
      }
    });
  }

  public update() {
    this.updateProgress = 0;
    this.versionUpdateService.updateSoftware(this.updateUi).then(() => {
      this.updateProgress = 100;
      window.setTimeout(() => {
        // hard reload
        const href = this.versionUpdateService.appData + '/app/index.html';
        console.log('href', href);
        window.location.href = href;
      }, 1000); // after 1 second; give user some time to register that we're done.
    }).catch(err => {
      console.error('update error', err);
      this.error = err;
    });
  }

  public proceed() {
    this.versionUpdateService.skipUpgrade = true;
    this.router.navigate(['/']);
  }
}
