import { EventEmitter, Injectable } from '@angular/core';
import { TokenService } from '@sst-inc/angular-auth';
import { Subject, Subscription, pipe } from 'rxjs';
import * as wait from 'wait-promise';

import { Config } from '../../framework/config';
import { DateService } from '../../services/date.service';
import { SessionEventService } from '../../sessionEvents/sessionEvent.service';
import { Notification } from './notification';
import { SubscriptionService } from './subscription.service';
import Debug from 'debug';
import { iconColors } from './iconColors';
import { CordovaService } from 'src/app/cordova/cordova.service';
import { skip } from 'rxjs/operators';

const debug = Debug('sst:push.service');
declare const PushNotification: any;

@Injectable({
  providedIn: 'root'
})
export class PushService {
  public registrationId: string;
  public ready = new EventEmitter();
  public _ready = false;

  private push;
  private events = new Map<string, Subject<any>>();
  private resumeCount = 0;
  private backgroundSubscription: Subscription;

  constructor(private subscriptionService: SubscriptionService,
              private config: Config,
              private tokenService: TokenService,
              private dateService: DateService,
              private cordovaService: CordovaService,
              private sessionEventService: SessionEventService
  ) {
    this.backgroundSubscription = this.cordovaService.isInBackground$
      .pipe(skip(1)) // skip initial state
      .subscribe(inBackground => {
        if (inBackground) {
        // ignore
        } else {
        // resume
          this.onResume();
        }
      });
  }

  public fromEvent(eventName: string): Subject<any> {
    if (!this.events.has(eventName)) {
      this.events.set(eventName, this.makeEventSubject(eventName));
    }
    const eventSubject = this.events.get(eventName);

    return eventSubject;
  }

  public initPush(): Promise<any> {
    debug('initPush');

    // unregister unbinds the event handlers below. no need to unbind them directly.
    const unregister = this.push ? this.push.unregister(() => {
      debug('successfully unregistered');
    }) : Promise.resolve('not yet registered');

    const init = new Promise((resolve, reject): any => {
      // console.log('pushService initPush ',device.model,device.uuid,device.platform,device.version);
      //
      const iconColor = iconColors[this.config.build.env] || iconColors.prod;

      this.push = PushNotification.init({
        android: {
          clearNotifications: false,
          icon: 'notif', // note: notif.png, original is cordova/res/icon
          iconColor,
          senderID: 46033659787
        },
        ios: {
          alert: 'true',
          badge: 'true',
          sound: 'true'
        },
        windows: {}
      });

      this.push.on('registration', data => {
        this.onRegistration(data).then(success => {
          resolve(success);
        }).catch(rejected => {
          this.onError(rejected);
        });
      });
      this.push.on('error', this.onError.bind(this));
      this.push.on('notification', this.onNotification.bind(this));
    });

    return Promise.all([unregister, init]).then(([unregisterRes, initRes]) => {
      debug('unregisterRes', unregisterRes);
      debug('initRes', initRes);
      return initRes;
    });
  }

  public registerWithServer() {
    if (!this.registrationId) {
      return Promise.reject(new Error('invalid registration id'));
    }
    const cordovaDevice: any = window.device;
    const option = {
      devicetoken: this.registrationId,
      model: cordovaDevice.model,
      name: cordovaDevice.uuid,
      sstBuild: this.config.buildNum, // todo service should use version info in headers.
      sstVersion: this.config.version, // todo service should use version info in headers.
      type: cordovaDevice.platform,
      version: cordovaDevice.version
    };

    // console.log('registration ',option);

    return this.subscriptionService.registerDevice(option).then(result => {
      debug('successful push registration', result);
    }).catch(err => {
      console.error('unable to register for push notifications');
      this.sessionEventService.addEvent('ui.push-registration-failure', {
        registrationid: this.registrationId
      });

      throw err;
    });
  }

  private onNotification(data) {
    debug('on push notification', data);

    if (!this.events.size) {
      // initial startup failed
      // but maybe we can recover
      this.ready.emit(); // will force listeners in notification service to be rebuilt
    }

    let notification: Notification;
    if (this.config.build.platform === 'android') {
      notification = data.additionalData.payload;
      notification.coldstart = data.additionalData.coldstart;
      notification.foreground = data.additionalData.foreground;
    } else {
      // ios apn case
      // note: this pollutes the notification with push messaging metadata,
      // such as 'foreground', 'coldstart' (and possibly other) properties
      notification = data.additionalData;
    }

    notification.receivedOn = this.dateService.getDate();

    const eventName = notification.eventType;
    if (!eventName) {
      // we were seeing some odd empty payloads in development (which never gets push payloads)
      // drop them on the floor
      return;
    }

    // there may be a delay registering for these events
    // wait until the event has been registered
    wait.every(100, 600) // every 100ms for 1 minute
      .until(() => this.events.has(eventName))
      .then(() => {
        const subject = this.events.get(eventName);
        subject.next(notification);
      }).catch(err => {
        console.error('push.service error', err);
        debug('error waiting for ' + eventName, err);
      });
  }

  private onRegistration(data) {
    if (!(data && data.registrationId)) {
      console.error('unable to register with push notification provider', data);
      return Promise.reject(new Error('unable to register with push notification provider'));
    }
    /* android only manage channels after registration
     * because default channel is created just before this.
     */
    if (this.config.build.platform === 'android') {
      this.createAndroidChannels();
    }

    this.resumeCount = 0;
    this.registrationId = data.registrationId;

    return this.registerWithServer().then(() => {
      if (!this._ready) {
        this._ready = true;
        // listeners to this set up event handlers, don't do that more than once.
        this.ready.emit();
      }
    });

    // test
    // this.onNotification(testNotification);
  }

  private createAndroidChannels() {
    /*
    PushNotification.listChannels((channels) => {

      for (const channel of channels) {
        console.log(`ID: ${channel.id} Description: ${channel.description}`);
      }

    }, (err) => {
      console.error('error listing channels', err);
    });
    */

    PushNotification.deleteChannel(() => {
      // console.log('successfully deleted default channel');
    }, err => {
      console.error('error deleting default channel', err);
    }, 'PushPluginChannel');

    // create new default/only channel

    const defaultChannel = {
      description: 'ShotSpotter Alerts',
      id: 'alerts',
      importance: 5,
      sound: 'sstalert'
    };

    PushNotification.createChannel(
      () => {
        debug('sstalert push channel created');
      },
      err => {
        console.error('error creating channel', err);
      },
      defaultChannel
    );
  }

  private onError(err) {
    console.error('push notification error', err);
    this.sessionEventService.addEvent('ui.push-registration-failure', {
      error: err.message,
      registrationid: this.registrationId
    });
  }

  private makeEventSubject(eventName): Subject<any> {
    const eventSubject = new Subject();

    return eventSubject;
  }

  private onResume = () => {
    this.resumeCount += 1;
    if (this.resumeCount < 2) {
      return; // startup after registration case
    }
    if (!this.events.size) {
      // initial startup failed
      // but maybe we can recover
      this.ready.emit(); // will force listeners in notification service to be rebuilt
    }
    if (!this.tokenService.getToken()) {
      return; // ignore
    }

    debug('onresume');
    if (this.registrationId) {
      this.registerWithServer().then(() => {
        debug('registration after resume successful');
      }).catch(err => {
        console.error('registration after resume unsuccesful', err);
      });
    } else if (navigator.onLine) {
      this.initPush().then(() => {
        debug('initialized push after resume');
      });
    }
  };
}
