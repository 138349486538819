import { Injectable } from '@angular/core';

import { RestService } from '../../services/rest.service';
import { Config } from './../../framework/config';
@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  private serviceUrl: string;
  constructor(public config: Config, public restService: RestService) {
    this.serviceUrl = this.config.getServiceUrl('notification-service');
  }

  public registerDevice(payload) {
    const url = this.serviceUrl + 'api/subscription/registerdevice';
    // console.log('register device', payload);

    return this.restService.postToEndpoint(url, payload).toPromise();
  }

  public getSubscriptions() {
    const url = this.serviceUrl + 'api/subscription';
    return this.restService.getFromAuthenticatedEndpoint(url).toPromise();
  }
}
