import { Component, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Config } from '../framework/config';
import { Browser } from './browserInfo';

@Component({
  // note: required to override the html element style
  // if you refactor, find another alternative to adding scrollbar to the html element
  encapsulation: ViewEncapsulation.None,
  selector: 'supportedBrowsers',
  templateUrl: './supportedBrowsers.component.html'
})
export class SupportedBrowsersComponent {
  constructor(private titleService: Title, public browser: Browser, private config: Config) {
    this.titleService.setTitle(config.appTitle + ' - supported browsers');
  }
}
