import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateService {
  // public dateFormat = 'L';
  // public timeFormat = 'HH:mm:ss';
  // public dateTimeFormat = 'L @ HH:mm:ss';
  // Set default for locale settings from 'en-US'
  public localeSettings = {
    dateFormat: 'MM/DD/YYYY',
    timeFormat: 'HH:mm:ss',
    dateTimeFormat: 'MM/DD/YYYY HH:mm:ss',
    dateTimeFormatWithSeparator: 'MM/DD/YYYY @ HH:mm:ss',
    dateTimeFormatWithMilisecondsWithSeparator: 'MM/DD/YYYY @ HH:mm:ss.SSS',
    timeFormatWithMiliseconds: 'HH:mm:ss.SSS',
    separator: '@',
    decimalSeparator: '.',
    thousandSeparator: ',',
    momentLocale: 'en-US',
    numberLocale: 'en-US',
    mapLocale: 'es-US'
  };
  private dateOffset = 0;

  constructor() {
    window.sst.dateService = this;

    if (window.localStorage.getItem('dateOverride')) {
      const now = Date.now();
      const then = new Date(window.localStorage.getItem('dateOverride'));

      if (then.toString() === 'Invalid Date') {
        return;
      }

      this.dateOffset = then.getTime() - now;
    }
  }

  public getDate(theDate?) {
    if (theDate) {
      return new Date(theDate);
    }

    const now = Date.now();

    const newDate = new Date(now + this.dateOffset);

    return newDate;
  }
}
