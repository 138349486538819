
if (localStorage.countTimers) {
  const timers = {
    length: () => {
      return Object.keys(timers).length;
    }
  }; // pending timers will be in this variable;
  window['timers'] = timers;
  const originalSetTimeout = window.setTimeout;
  // override `setTimeout` with a function that keeps track of all timers
  (window as any).setTimeout = (cb: () => void, t: number) => {
    const id = originalSetTimeout(() => {
      console.log(id + ' has timed out');
      delete timers[id]; // do not track popped timers
      cb();
    }, t);
    // track this timer in the `timers` object
    timers[id] = { id, setAt: new Date(), timeout: t };
    console.log(id + ' has been set to pop in ' + t + 'ms');
    return id;
  };
  // from this point onward all uses of setTimeout will be tracked,
  // logged to console and pending timers will be kept in the global variable 'timers'.
}

