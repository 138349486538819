/* note, this is the base class/type for all environments.
 * other environments will _merge_ their properties onto this
 *
 * to satisfy typescript typing, THIS class/file must have
 * all new properties/objects added to it
 *
 * those properties are optional in the environment specific files.
 */
import buildInfo from '../build-info';
// This is a dev env running on localhost, can't guess domain based on hostname
const domain = 'soundthinking.com';

// After self-updating legacy electron wrapper to rebranded ShotSpotter app
// the code will get appId from the global `appId` variable set in electron.js.
// But this global var is new, and electron.js is only evaluated on launch.
// This causes an issue where login will fail after the self-update, the user
// needs to restart the app to pick up the change.
//
// TODO: maybe need better UX for this edge case
if (
  buildInfo.platform === 'electron' &&
  !window.sstElectronApi &&
  !window['require']('electron').remote.getGlobal('appId')
) {
  alert('The update was successful, please close and re-open the application or login will fail.');
}

export interface IOAuth2Config {
  clientId: string;
  logoutRedirectUri: string;
  openIdConnectUrl: string;
  redirectUri: string;
}

export const environment = {
  envName: 'int',
  loadFullStory: false,
  heapKey: undefined,
  heapLogLevel: undefined,
  endpoints: {
    'auth-service': '/api/auth/v1/',
    'geodata-service': '/api/geodata',
    'incident-service': '/api/incidentservices/',
    'messages-service': '/api/message/v1/',
    'notification-service': '/api/notification/',
    'report-service': '/api/reports/v1/',
    'socket-service': '/api/socket-v2/ws',
    'subscription-service': '/api/subscription/v1/',
    'user-service': '/api/user/v1/',
    'gps-service': '/api/gps-gateway/',
    'ims-service': '/api/ims/v1/',
    'settings-service': '/api/setting/v1/'
  },
  // googleAPIKey: 'AIzaSyADEJezsp9wYUnqEt9J2_KV1Q-7niayqig',
  // temporarily use production key in integration
  googleAPIKey: 'AIzaSyAzAGuD-QnI2uQW2d9ikV-uMzd9I3g8IPo',
  svcHost: `https://svc.int.${domain}`,
  // For legacy electron self-update
  updateSourceUrl: 'https://shotspotter.int.shotspotter.com/auto-update2',
  mapReloadTimeoutMins: 23 * 60,
  oauth2: {},
  sentry: {}
};
