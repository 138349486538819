import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import * as Sentry from '@sentry/browser';
import { AuthService, TokenService } from '@sst-inc/angular-auth';
import * as Debug from 'debug';
import { BehaviorSubject, forkJoin, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, filter, skip, tap } from 'rxjs/operators';

import { Config } from '../framework/config';
import { UtilService } from '../framework/dom.util';
import { Permission } from '../models/permission.model';
import { User } from '../models/user';
import { IncidentSubscription } from '../system-panel/subscriptions/subscription';
import { PreferencesService } from './preferences.service';
import { RestService } from './rest.service';
import { SystemSettingsService } from './system-settings.service';
import { UIStateService } from './uiState.service';
import { cloneDeep, debounce } from 'lodash';
import { TranslateService } from '@tolgee/ngx';
import { DateService } from './date.service';
import { resolveLanguage } from '../availableLanguages';

const debug = Debug('sst:user-service');

interface IPermissionDTO extends Permission {
  roles: string[]
}

const englishPhrases: { [key:string]: string} = {
  'switch-label.on': 'ON',
  'switch-label.off': 'OFF'
};
let phrases: { [key:string]: string};

@Injectable({
  providedIn: 'root'
})
export class UserService implements CanActivate {
  // note: lowercase...
  public notSelectableRoles = ['shotspotter_forensic']; // hide in the UI (don't let toggle)
  public user: User;
  public currentRegion: string;
  public languageOverride = 'en'; // default if the global setting is not available.
  public subscriptions$ = new BehaviorSubject<IncidentSubscription[] | undefined>(undefined);

  public permissionsByRole: { [key: string]: Permission[] } = {};

  public permissionStatus = new Map(
    [
      ['acknowledge_allow_incident', { name: 'Acknowledge incidents', isAuthorizedFor: false, active: false }],
      ['update_allow_incident', { name: 'Add/update CAD ID', isAuthorizedFor: false, active: false }],
      ['write_allow_incident.comment', { name: 'Add/update comments', isAuthorizedFor: false, active: false }],
      ['update_allow_satellite-map', { name: 'Select map type', isAuthorizedFor: false, active: false }],
      ['read_allow_incident.audio', { name: 'Listen to Audio', isAuthorizedFor: false, active: false }],
      ['update_allow_silence-ack-warning',
        { name: 'Silence acknowledgment warnings', isAuthorizedFor: false, active: false }],
      ['read_allow_incident.ground-truth',
        { name: 'View ground truth', isAuthorizedFor: false, active: false }],
      ['write_allow_incident.ground-truth',
        { name: 'Record ground truth', isAuthorizedFor: false, active: false }],
      ['read_deny_incident.ground-truth',
        { name: 'Unable to view ground truth', isAuthorizedFor: false, active: false }]
    ]
  );

  public permissionStatus$ = new BehaviorSubject<
    Map<string, { name: string; isAuthorizedFor: boolean; active: boolean }>>(undefined);

  public roles = [] as string[];

  public currentActiveRoles = []; // this is used by system panel to check what roles are active from LocalStorage to set initial state
  public manageSubscriptionsEnabled = false;
  public showLatLngOnIncidentDetail = false;
  public mapTypeToggleEnabled = false;
  public showDataTransparency = false;
  public responseWindow = 10;
  public user$ = new BehaviorSubject<User>(undefined);
  public ready$ = new ReplaySubject<User>();
  public locationSource: string;
  public responseRadius: number; // meters
  public responseEntryTimeLimit: number; // minutes
  public responseExitTimeLimit: number; // minutes after entry
  public copToDotEnabled = false;
  public groundTruthEnabled = false;
  public autolinkDomainWhitelist = [];
  private authServiceUrl: string;
  private userServiceUrl: string;
  private subscriptionServiceUrl: string;

  /* isAuthorizeFor needs to be performant so it can be used inside angular templates
   * so we will memoize the function (cache results);
   * but the cache needs to be cleared when the list of permissions is updated
   * so we needed to expose the cache (which is normally inside memoize closure)
   * so that it can be cleared
   */
  private authCache: any = {};
  // tslint:disable-next-line
  public isAuthorizedFor = UtilService.memoize(
    this.authCache,
    (resource: string, action: string) => {
      return this.user
        ?.allowedActions
        ?.includes(`${resource}:${action}`);
    }
  ) as (resource: string, action: string) => boolean; // memoized

  // for convenience in this service, as they're related to permissions values.
  public switchLabels = { on: '', off: '' };

  constructor(
    private dateService: DateService,
    private config: Config,
    private restService: RestService,
    private authService: AuthService,
    private tokenService: TokenService,
    private preferences: PreferencesService,
    private systemSettingsService: SystemSettingsService,
    private translateService: TranslateService,
    private uiStateService: UIStateService
  ) {
    for (const [k, v] of this.permissionStatus) {
      englishPhrases[k] = v.name;
    }

    phrases = cloneDeep(englishPhrases);

    Object.keys(englishPhrases).forEach(k => {
      const tolgeekey = 'user.service.permissionStatus.' + k;
      this.translateService.translate(tolgeekey, englishPhrases[k])
        .subscribe(v => {
          phrases[k] = v;
          const ps = this.permissionStatus.get(k);
          // might be another phrase, test first
          if (ps) {
            ps.name = v; // this is the important one.
          }
          debounce(() => {
            // after all translations are loaded
            this.switchLabels.on = phrases['switch-label.on'];
            this.switchLabels.off = phrases['switch-label.off'];
          }, 50)();
        });
    });

    this.authServiceUrl = this.config.getServiceUrl('auth-service');
    this.userServiceUrl = this.config.getServiceUrl('user-service');
    this.subscriptionServiceUrl = this.config.getServiceUrl('subscription-service');

    // isSignedIn is triggered only if signed in status changes
    // but not when access token is renewed
    this.tokenService.isSignedIn().pipe(skip(1)).subscribe(signedIn => {
      debug('isSignedIn', signedIn);
      if (!signedIn) {
        // On web this doesn't happen (app starts fresh after redirect)
        // On Cordova state is maintained so we need to clear on logout
        this.roles = [];
        this.restService.clearCache();
        this.subscriptions$.next(undefined);
        Sentry.configureScope(scope => {
          scope.setUser({});
          return scope;
        });
        this.user = undefined;
        this.restService.isLoggedOut = true;
        this.user$.next(undefined);
      } else {
        this.restService.isLoggedOut = false;
        if (!this.user) {
          this.user = {} as User;
          this.updatePermissionsAndSettings().then(() => {
            debug('permissions and settings updated as a result of user change');
          });
        }
      }
    });

    this.authService.onUserChange().subscribe(() => {
      debug('onUserChange');
      Sentry.setUser({
        email: this.authService.getUserInfo().email,
        id: this.authService.getUserInfo().sub,
        userName: this.authService.getUserInfo().preferred_username
      });
      // NB: the `!this.user` case is handled by canActivate
    });
  }

  public updateSubscriptions(subscriptions: IncidentSubscription[]) {
    const subscriptionsUrl = this.subscriptionServiceUrl + 'mysubscriptions';
    const subsRequest = this.restService.performHTTPRequest('PATCH', subscriptionsUrl, {
      subscriptions
    }, true);
    return subsRequest;
  }

  public getAndSaveSubscriptions() {
    const subscriptionsUrl = this.subscriptionServiceUrl + 'mysubscriptions';
    const subsRequest = this.restService.getFromAuthenticatedEndpoint(subscriptionsUrl).pipe(tap(subscriptions => {
      debug('subscriptions', subscriptions);
      if (subscriptions) {
        this.subscriptions$.next(subscriptions.map(s => {
          s.isSubscribed = true; // this endpoint only returns subscribed entities
          return new IncidentSubscription(s);
        }));
      }
    }));
    return subsRequest;
  }

  // will always return true or reject
  public updatePermissionsAndSettings(): Promise<boolean> {
    const permissionsUrl = this.authServiceUrl + 'permission';
    const permissionsRequest = this.restService.getFromAuthenticatedEndpoint(
      permissionsUrl
    );

    const settingsUrl = this.authServiceUrl + 'appsettings';
    // const settingsUrl = 'api/setting.json'; // ?ts=' + ts;
    const settingsRequest = this.restService.getFromAuthenticatedEndpoint(
      settingsUrl
    );
    const userUrl = this.userServiceUrl + 'me';
    const userRequest = this.restService.getFromAuthenticatedEndpoint(userUrl);

    const subsRequest = this.getAndSaveSubscriptions();

    const systemSettingsRequest = this.systemSettingsService.getSystemSettings();

    return forkJoin([
      permissionsRequest,
      settingsRequest,
      userRequest,
      subsRequest,
      systemSettingsRequest
    ])
      .toPromise()
      .then((result: any) => {
        // In case all requests fail (network down), result will be undefined
        if (!result) {
          this.user = undefined;
          this.roles = [];
          return;
        }
        let [permResult, settingsResult, userResult] = result;
        // ignoring subsResult  and systemSettingsResult here as side-effects
        // (in updateSubscriptions() for subs ) handles it.
        // permissions will be restricted, but we'll let you into the app
        // to see the incident details.
        permResult = permResult || {};
        settingsResult = settingsResult || [];
        if (!userResult) {
          // can't do anything without at least basic user
          console.warn('unable to retrieve user');
          return;
        }

        this.user = new User(userResult);

        this.roles = this.user.roles; // ignore permResult.roles, because it's filtered by application // = permResult.roles || [];

        // shotspotter users may override their language
        // customers cannot by default, although we will honor the agency setting for this.
        // note: shotspotter agency 0 agency setting _will_ have '' as the default setting
        this.languageOverride = this.user.agencyId ? 'en' : '';

        const settings = this.user.agency && this.user.agency.settings;
        let parsedValue: any[];

        if (settings) {
          settings.forEach(setting => {
            switch (setting.key) {
            case 'respondUserManagedSubscriptions':
              this.manageSubscriptionsEnabled = setting?.value;
              break;
            case 'appShowLatLongOnIncident':
              this.showLatLngOnIncidentDetail = setting?.value;
              break;
            case 'respondEnableMapTypeToggle':
              this.mapTypeToggleEnabled = setting?.value;
              break;
            case 'appsShowDataTransparency':
              this.showDataTransparency = setting?.value;
              break;
            case 'connect__locationSource':
              this.locationSource = setting && setting.value;
              break;
            case 'respond__ResponseWindow':
              // note, this responseWindow is the generic '10 minute rule'
              // NOT the response tracking time limit for cop-to-dot
              if (setting) {
                this.responseWindow = setting.value;
              }
              break;
            case 'respond__responseEntryTimeLimit':
              // cop-to-dot
              this.responseEntryTimeLimit = +localStorage.responseEntryTimeLimit ||
                  ((setting?.value === undefined) ? 15 : +setting.value);
              break;
            case 'respond__responseExitTimeLimit':
              this.responseExitTimeLimit = +localStorage.responseExitTimeLimit ||
                  ((setting?.value === undefined) ? 120 : +setting.value);
              break;
            case 'respond__responseRadius':
              this.responseRadius = +localStorage.responseRadius ||
                  ((setting?.value === undefined) ? 25 : +setting.value);
              break;
            case 'respond__responseCopToTheDot':
              this.copToDotEnabled = !!localStorage.copToDotEnabled ||
                  (!!(setting?.value));
              break;
            case 'respond__groundTruthCollection':
              this.groundTruthEnabled = !!localStorage.groundTruthEnabled ||
                  (!!(setting?.value));
              break;
            case 'global__localeSettings':
              this.dateService.localeSettings = setting.value;
              this.currentRegion = this.dateService.localeSettings.mapLocale;
              break;
            case 'global__forcedLanguageOnApps':
              // setting.value: '' | 'en' | 'es'
              this.languageOverride = setting.value;
              if (this.languageOverride) {
                // resolves 'es' to 'es-416
                this.languageOverride = resolveLanguage(this.languageOverride);
              }
              break;
            case 'global__autolinkDomainWhitelist':
              if (typeof setting.value === 'string') {
                try {
                  parsedValue = JSON.parse(setting.value);
                } catch (error) {
                  debug('Error parsing JSON:', error);
                  parsedValue = [];
                }
              }
              this.autolinkDomainWhitelist = parsedValue;
              break;
            default:
              debug('unused setting', setting);
            }
          }); // foreach setting
        } // if settings

        if (permResult.allowedActions) {
          this.user.allowedActions = permResult.allowedActions;
        }

        if (permResult.permissions) {
          this.parsePermissionsList(permResult.permissions);
          this.updatePermissions(permResult.permissions);
          const savedRoles = this.getRoleSelectionFromLS();
          if (savedRoles?.length) {
            const cleaned = this.cleanSavedRoles(savedRoles);
            this.updateRolesAndPermissions(cleaned);
          } else {
            this.clientPermissions(this.user);
            this.permissionStatus$.next(this.permissionStatus);
          }
        }

        // then merge user settings
        // user settings will prevail
        this.preferences.setDefaults(settingsResult);
        // Notify about updated user
        this.user$.next(this.user);
        this.ready$.next(this.user);

        return true;
      })
      .catch(err => {
        debug('error getting permissions', err);
        if (err.message.includes('ShotSpotter User role is required')) {
          this.authService.silentLogout();
          this.reload();
        }
        return false;
      });
  }

  private reload() {
    let isElectron = false;
    if (this.config.build.platform === 'electron' && !window.sstElectronApi) {
      // This if block is only for legacy electron (file://)
      this.uiStateService.isReloading = true; // flag for app.module.ts onbeforeunload handler
      isElectron = true;
    }
    UtilService.reload(isElectron);
  }

  private parsePermissionsList(permissions: IPermissionDTO[]) {
    // creates a mapping of the permissions associated to the Respond roles,
    // to be used when changing roles in the app.
    // add the client-only permissions in the initial object
    const initial: { [key: string] : Permission[] } = {
      'Shotspotter_Respond': [],
      'Shotspotter_Dispatch': [
        {
          action: 'update',
          effect: 'allow',
          resource: 'satellite-map'
        }
      ],
      'Shotspotter_Rtcc': [
        {
          action: 'update',
          effect: 'allow',
          resource: 'silence-ack-warning'
        },
        {
          action: 'update',
          effect: 'allow',
          resource: 'satellite-map'
        }
      ],
      'Shotspotter_Viewer': [
      ],
      'Shotspotter_Forensic': [
      ]
    };

    const permissionsList = permissions.reduce((all, current) => {
      current.roles.forEach(role => {
        if (initial[role]) {
          initial[role].push({
            action: current.action,
            effect: current.effect,
            resource: current.resource
          });
        }
      });
      return all;
    }, initial);
    debug('permList', permissionsList);
    this.permissionsByRole = permissionsList;
  }

  public onUser() {
    return this.user$
      .pipe(
        distinctUntilChanged(),
        filter(user => !!user)
      );
  }

  // remove any roles from the saved set if the user no longer has that role
  private cleanSavedRoles(savedRoles: string[]) {
    return savedRoles.filter(r => this.user.roles.includes(r));
  }


  public async canActivate() {
    debug('canActivate');
    if (!this.user) {
      debug('new user');
      this.user = {} as User; // avoids the isSignedIn() event calling this same method
      await this.updatePermissionsAndSettings();
    }
    debug('existing user');
    return true;
  }

  private updatePermissions(permissions: Permission[]) {
    this.user.permissions = [...permissions];
    this.resetPermissionStatus();

    this.user.permissions.forEach(p => {
      const key = `${p.action}_${p.effect}_${p.resource}`;
      const permission = this.permissionStatus.get(key);

      if (permission) {
        permission.active = true;
        permission.isAuthorizedFor = key.includes('allow');
        permission.isAuthorizedFor = true;
      }
    });

    this.handleDenyReadGroundTruth();
    this.bustAuthCache();
  }

  private handleDenyReadGroundTruth() {
    // special case for deny ground truth
    // could possibly refactor to allow arbitrary resources..
    const denyPerm = this.permissionStatus.get('read_deny_incident.ground-truth');
    if (denyPerm?.active) {
      [
        'write_allow_incident.ground-truth',
        'read_allow_incident.ground-truth'
      ].forEach(key => {
        const perm = this.permissionStatus
          .get(key);
        if (perm) {
          perm.active = false;
        }
      });
    }
  }

  private bustAuthCache() {
    // bust auth cache
    for (const prop of Object.keys(this.authCache)) {
      delete this.authCache[prop];
    }
  }

  private resetPermissionStatus() {
    // create a set of keys for permissions the user has the roles for...
    const activePermissions = new Set();
    Object.keys(this.permissionsByRole).forEach(r => {
      if (this.user.roles.find(role => role === r)) {
        this.permissionsByRole[r].forEach(p => activePermissions.add(`${p.action}_${p.effect}_${p.resource}`));
      }
    });
    const activeList = [...activePermissions];

    const permissionStatus = new Map();
    for (const key of this.permissionStatus.keys()) {
      const permission = this.permissionStatus.get(key);
      permission.isAuthorizedFor = false;

      permission.active = activeList.includes(key);
      permissionStatus.set(key, permission);
    }
    this.permissionStatus = permissionStatus;
  }

  private clientPermissions(user: User, roles?: string[]) {
    // in order to handle in-app changes to these permissions, clear them first
    // they will get re-added if it matches any of the conditions below

    const filterKeys = [
      'update_allow_engineering-settings',
      'update_allow_satellite-map',
      'update_allow_silence-ack-warning'
    ];

    user.permissions = user.permissions.filter(p => {
      const key = `${p.action}_${p.effect}_${p.resource}`;
      return !filterKeys.includes(key);
    });

    const userRoles = roles ? roles : user.roles;

    if (userRoles.includes('Siren_Engineering')) {
      user.permissions.push({
        action: 'update',
        effect: 'allow',
        resource: 'engineering-settings'
      });
    }

    const satPerm = this.permissionStatus.get('update_allow_satellite-map');
    if (userRoles.includes('Shotspotter_Dispatch')) {
      user.permissions.push({
        action: 'update',
        effect: 'allow',
        resource: 'satellite-map'
      });
      satPerm.active = true;
      satPerm.isAuthorizedFor = true;
    }

    const silencePerm = this.permissionStatus.get('update_allow_silence-ack-warning');
    const satPerm2 = this.permissionStatus.get('update_allow_satellite-map');
    if (userRoles.includes('Shotspotter_Rtcc')) {
      const perm = {
        action: 'update',
        effect: 'allow',
        resource: 'silence-ack-warning'
      };
      user.permissions.push(perm);
      silencePerm.active = true;
      silencePerm.isAuthorizedFor = true;

      // add this client permission depending whether it's already been added or not
      if (!userRoles.includes('Shotspotter_Dispatch')) {
        user.permissions.push({
          action: 'update',
          effect: 'allow',
          resource: 'satellite-map'
        });
        satPerm2.active = true;
        satPerm2.isAuthorizedFor = true;
      }
    }
  }


  // This will be called from system settings component to update permissions with selected roles
  // Create new list of user roles to set permissions, but DO NOT mutate the actual user.roles list, as it's used elsewhere
  public updateRolesAndPermissions(roles: string[]) {
    this.saveRoleSelectionToLS(roles);
    this.currentActiveRoles = roles;
    const appRoles = Object.keys(this.permissionsByRole);

    const toAdd = roles.filter(role => {
      return !this.user.roles.includes(role);
    });

    const toRemove = appRoles.filter(role => {
      if (this.notSelectableRoles.includes(role.toLowerCase())) {
        return false; // don't remove this role, it will always be selected.
      }

      return !roles.includes(role);
    });

    const userRoles = [...this.user.roles, ...toAdd].filter(role => !toRemove.includes(role));

    debug('role updates', this.user.roles);
    this.updateUserPermissions(userRoles);
  }

  private updateUserPermissions(roles: string[]) {
    // clear all respond role permissions... they'll get reset below;
    const toFilter = Object.values(this.permissionsByRole).reduce((acc, curr) => {
      acc = [...acc, ...curr];
      return acc;
    }, []);
    const filteredPermissions = this.user.permissions.filter(p => {
      const key = `${p.resource}_${p.effect}_${p.action}`;
      return !toFilter.find(fp => `${fp.resource}_${fp.effect}_${fp.action}` === key);
    });


    // concat all permissions associated to the selected roles
    const rolePermissions: Permission[] = roles.reduce((permissions, role) => {
      const rolePermissionList = this.permissionsByRole[role];
      if (rolePermissionList) {
        permissions = [...permissions, ...rolePermissionList];
      }
      return permissions;
    }, []);

    // concat all the user roles with the Respond specific roles
    const allPermissions = [...filteredPermissions, ...rolePermissions];

    // Not sure if this ever happens, but remove any duplicates of permissions in the list
    // in case of permissions overlap between roles
    const uniqueMap = new Map();
    allPermissions.forEach(p => uniqueMap.set(`${p.resource}_${p.effect}_${p.action}`, p));
    const unique = [...uniqueMap.values()];

    // update the user permissions and alert the rest of the app.
    this.updatePermissions(unique);
    this.clientPermissions(this.user, roles);
    this.updateAllowedActions();
    this.permissionStatus$.next(this.permissionStatus);
    this.user$.next(this.user);
    this.refreshIncidentListAfterPermissionsChange();
  }

  private updateAllowedActions() {
    const permissions = this.user.permissions;
    // note: do not change the code below
    // this code is copied from back-end ACL code.
    // if it needs changing, it needs to be changed in both places.
    const allowedActions = new Set();
    // Add allowed permissions first
    for (const permission of permissions) {
      if (permission.effect === 'allow') {
        allowedActions.add(`${permission.resource}:${permission.action}`);
      }
    }
    // Then remove denied permissions (deny takes precedence over allow)
    for (const permission of permissions) {
      if (permission.effect === 'deny') {
        allowedActions.delete(`${permission.resource}:${permission.action}`);
      }
    }
    this.user.allowedActions = [...allowedActions.keys()].map(k => k.toString());
    this.bustAuthCache();
  }

  private refreshIncidentListAfterPermissionsChange() {
    const current = this.uiStateService.selectedFilterOption$.getValue();
    if (!current) {
      return;
    }
    current.incidentId = this.uiStateService.selectedIncident$.getValue()?.sourceIncidentId;
    this.uiStateService.selectedFilterOption$.next(current);
  }

  private saveRoleSelectionToLS(roles: string[]) {
    const key = `${this.user.userName}_selected_roles`;
    localStorage.setItem(key, roles.toString());
  }
  private getRoleSelectionFromLS() {
    const key = `${this.user.userName}_selected_roles`;
    const roles = localStorage.getItem(key);

    return roles?.split(',') || [];
  }
}
