import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import * as Debug from 'debug';

import build from '../../build-info';
import { DateService } from '../services/date.service';

const debug = Debug('sst:dom.util');
declare const cordova;
declare const SafariViewController;

/* eslint-disable */
function onVisibilityChange(callback) {
  // https://stackoverflow.com/a/38710376/521042
  let visible = true;

  if (!callback) {
      throw new Error('no callback given');
  }

  function focused() {
      if (!visible) {
          callback(visible = true);
      }
  }

  function unfocused() {
      if (visible) {
          callback(visible = false);
      }
  }

  // Standards:
  if (typeof document.hidden !== 'undefined') {
      document.addEventListener('visibilitychange', () => {
        (document.hidden ? unfocused : focused)();
      });
  } else if (typeof document['mozHidden'] !== 'undefined') {
      document.addEventListener('mozvisibilitychange', () => {
        (document['mozHidden'] ? unfocused : focused)();
      });
  } else if (typeof document['webkitHidden'] !== 'undefined') {
      document.addEventListener('webkitvisibilitychange', () => {
        (document['webkitHidden'] ? unfocused : focused)();
      });
  } else if (typeof document['msHidden'] !== 'undefined') {
      document.addEventListener('msvisibilitychange', () => {
        (document['msHidden'] ? unfocused : focused)();
      });
  }
  // IE 9 and lower:
  /*
  if ('onfocusin' in document) {
      document['onfocusin'] = focused;
      document['onfocusout'] = unfocused;
  }
  */
  // All others:
  window.onpageshow = window.onfocus = focused;
  window.onpagehide = window.onblur = unfocused;
}
/* eslint-enable */

onVisibilityChange((visible: boolean) => {
  const vis = new CustomEvent('vischanged', { detail: visible });
  document.dispatchEvent(vis);
});

// removes accent characters often found in non-english language words
export function normalize(str: string) {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  public static sounds = {};

  public static isDescendant(parent, child) {
    let node = child.parentNode;
    while (node !== undefined) {
      if (node === parent) {
        return true;
      }
      node = node.parentNode;
    }
    return false;
  }

  public static copyToClipboard(text: string): boolean | Error {
    const textArea = document.createElement('textarea');

    // Place in top-left corner of screen regardless of scroll position.
    textArea.style.position = 'fixed';
    textArea.style.top = '0';
    textArea.style.left = '0';

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = '2em';
    textArea.style.height = '2em';

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = '0';

    // Clean up any borders.
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';

    // Avoid flash of white box if rendered for any reason.
    textArea.style.background = 'transparent';

    textArea.value = text;

    document.body.appendChild(textArea);

    textArea.select();

    // return falsy on success, so exception error can be tested for.
    try {
      const successful: boolean = document.execCommand('copy');
      document.body.removeChild(textArea);
      return !successful;
    } catch (error) {
      console.error('error copying to clipboard', error);
      document.body.removeChild(textArea);
      return error;
    }
  }

  public static getClosest(elem, selector) {
  /**
   * Behaves like the jQuery '.closest()' function, but without jQuery.
   */
    if (!elem) {
      debug('getClosest invalid element', elem, selector);
      return false;
    }
    const firstChar = selector.charAt(0);

    // Get closest match
    for (; elem && elem !== document; elem = elem.parentNode) {
      // selector is a class
      if (firstChar === '.') {
        if (elem.classList.contains(selector.substr(1))) {
          return elem;
        }
      }

      // selector is an ID
      if (firstChar === '#') {
        if (elem.id === selector.substr(1)) {
          return elem;
        }
      }

      // selector is a data attribute
      if (firstChar === '[') {
        if (elem.hasAttribute(selector.substr(1, selector.length - 2))) {
          return elem;
        }
      }

      // selector is a tag
      if (elem.tagName && elem.tagName.toLowerCase() === selector) {
        return elem;
      }
    }
    return false;
  }

  public static playSound(type: string, loadOnly = false) {
    if (!loadOnly) {
      debug('playSound', type);
    }
    let sound = UtilService.sounds[type];
    if (!sound) {
      sound = new Audio('assets/sounds/' + type + '.mp3');
      UtilService.sounds[type] = sound;
    }

    const result = loadOnly ? sound.load() : sound.play();

    if (result) {
      // result is a promise! (non-firefox)
      // we can trap 'unhandled error in promise' error
      result.catch(e => {
        console.error('Problem Playing Sound: ', e);
        // clear the 'cached' sound object.
        // handles offline case
        // will reload correctly next time
        delete UtilService.sounds[type];
      });
    }

    return sound;
  }

  public static loadScript(url, delay = 0) {
    return new Promise<void>((resolve, reject) => {
      window.setTimeout(() => {
        const script = document.createElement('script');
        document.head.appendChild(script);
        script.src = url;

        script.onload = () => {
          resolve();
        };

        script.onerror = err => {
          // clean up
          document.head.removeChild(script);

          reject(err);
        };
      }, delay);
    });
  }

  public static debounce(fn, delay) {
    let timer = undefined;
    return function () {
      const context = this;
      const args = arguments; // eslint-disable-line
      clearTimeout(timer);
      timer = window.setTimeout(() => {
        fn.apply(context, args);
      }, delay);
    };
  }

  // https://jsfiddle.net/jonathansampson/m7G64/
  public static throttle(callback, limit) {
    let wait = false; // Initially, we're not waiting
    return function () { // We return a throttled function
      const context = this;
      const args = arguments; // eslint-disable-line
      if (!wait) { // If we're not waiting
        callback.apply(context, args); // Execute users function
        wait = true; // Prevent future invocations
        setTimeout(() => { // After a period of time
          wait = false; // And allow future invocations
        }, limit);
      }
    };
  }

  public static captureMessage(msg, data) {
    Sentry.configureScope(scope => {
      scope.setExtra('data', data);
      return scope;
    });
    Sentry.captureMessage(msg);
  }
  public static get mobileOperatingSystem() {
    // http://stackoverflow.com/a/21742107/521042
    const userAgent = navigator.userAgent || navigator.vendor || window['opera'];

    // Windows Phone must come first because its UA also contains 'Android'
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }

    if (/android/i.test(userAgent)) {
      return 'Android';
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window['MSStream']) {
      return 'iOS';
    }

    return 'unknown';
  }

  // adding global helper method to determine if we are running in cordova || just a narrow screen

  // public static readonly phoneBreak = 462;
  public static readonly phoneBreak = 1025;

  public static get isNarrowScreen() {
    // caching
    if (typeof UtilService._isNarrowScreen !== 'undefined') {
      return UtilService._isNarrowScreen;
    }

    return window.innerWidth <= this.phoneBreak;
  }

  public static get isMobile() {
    // caching
    if (typeof UtilService._isMobile !== 'undefined') {
      return UtilService._isMobile;
    }
    let os;
    // note: breakpoint should match break-phone in vars.scss
    if (typeof cordova !== 'undefined' || window.innerWidth <= this.phoneBreak) {
      UtilService._isMobile = true;
      return true;
    } else { // possible mobile browser
      os = UtilService.mobileOperatingSystem;
      if (['Android', 'iOS'].indexOf(os) !== -1) {
        // in a browser on a mobile device
        // window size detection isn't enough. people don't run browser windows full screen...
        this._isMobile = true;
        return true;
      }
      UtilService._isMobile = false;
      return false;
    }
  }
  // handle LONG setTimeouts gracefully
  // insipried by, with code added for clear()
  //
  // https://stackoverflow.com/a/18182660/521042
  public static runAtDate(date, func) {
    const now = (new Date()).getTime();
    const then = date.getTime();
    const diff = Math.max((then - now), 0);
    let timer;
    const result: any = {
      clear: () => {
        clearTimeout(timer);
      }

    };

    if (diff > 0x7FFFFFFF) { // setTimeout limit is MAX_INT32=(2^31-1)
      timer = setTimeout(() => {
        const newResult = this.runAtDate(date, func);
        result.clear = newResult.clear; // tricky, tricky
      }, 0x7FFFFFFF);
    } else {
      timer = setTimeout(func, diff);
    }

    return result;
  }

  public static memoize(cache: any, fn): any {
    return (...args) => {
      const stringifiedArgs = JSON.stringify(args);
      if (stringifiedArgs in cache) {
        return cache[stringifiedArgs];
      }
      const out = cache[stringifiedArgs] = fn(...args);
      return out;
    };
  }

  public static openWindow(e) {
    e.preventDefault();
    const url = e.currentTarget.href;

    // On Cordova, use Safari View/Custom Tabs only for http urls
    // for other protocols like mailto: we just do window.open
    if (window['cordova'] && /^https?:/.test(url)) {
      // Despite the name, this also works on Android and uses CustomTabs
      // See https://github.com/EddyVerbruggen/cordova-plugin-safariviewcontroller/wiki
      SafariViewController.isAvailable(available => {
        if (available) {
          SafariViewController.show({ url });
        } else {
          window.open(url, '_system');
        }
      });
    } else if (build.platform === 'electron') {
      if (window.sstElectronApi) {
        // we're running in new electron wrapper (which loads https:// url)
        // this works for all http,https,mailto urls.
        window.sstElectronApi?.openExternal(url);
      } else {
        // we're running in legacy electron wrapper (file://)
        // avoid webpack inclusion
        const shell = window['require']('electron').shell;
        shell.openExternal(url);
      }
    } else {
      window.open(url, '_system');
    }
  }

  public static openMailto(e) {
    // mailto are regular links handled natively on web and mobile,
    // however on electron we need to use shell.openExternal()
    // otherwise a blank window labeled "_system" will be opened for some reason
    const url = e.target.href;

    if (build.platform === 'electron') {
      e.preventDefault();
      if (window.sstElectronApi) {
        // we're running in new electron wrapper (which loads https:// url)
        window.sstElectronApi?.openExternal(url);
        return;
      } else {
        // we're running in legacy electron wrapper (file://)
        // avoid webpack inclusion
        const shell = window['require']('electron').shell;
        shell.openExternal(url);
      }
    }
  }

  public static contactSupport(e) {
    e.preventDefault();
    if (window['cordova']) {
      window['intercom'].displayMessenger();
    }
    // On other platforms, we tell Intercom to bind to
    // CSS class .contact-support
  }

  public static isFunction(functionToCheck) {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
  }

  private static _isMobile: boolean;
  private static _isNarrowScreen: boolean;

  constructor(private dateService: DateService) {
    window.addEventListener('resize', () => {
      delete UtilService._isMobile;
    });
  }

  public scrollTo(element: HTMLElement, x: number) {
    if (!element) {
      return;
    }
    debug('scrolled');
    element.scrollTop = 0;

    // hack to force repaints
    const display = element.style.display;
    element.style.display = 'inline-block';
    // eslint-disable-next-line
    element.offsetHeight;
    element.style.display = display;
  }

  public minSecsAgo(secondsAgo) {
    const prefix = secondsAgo < 0 ? '-' : '+';
    const klass = secondsAgo < 0 ? 'before' : 'after';
    secondsAgo = Math.abs(secondsAgo);

    const m = Math.floor(secondsAgo / 60);
    const s = (secondsAgo - (m * 60)).toString().padStart(2, '0');
    const label = `${prefix}${m}:${s}`;

    return { m, s, label, prefix, klass };
  }

  public hoursMinSecsAgo(secondsAgo) {
    const prefix = secondsAgo < 0 ? '-' : '+';
    const klass = secondsAgo < 0 ? 'before' : 'after';
    secondsAgo = Math.abs(secondsAgo);
    let secondsRemaining = secondsAgo;

    const hr = Math.floor(secondsRemaining / 3600);
    const h = hr.toString().padStart(2, '0');
    secondsRemaining -= hr * 3600;

    const mn = Math.floor(secondsRemaining / 60);
    const m = mn.toString().padStart(2, '0');
    secondsRemaining -= mn * 60;

    const s = secondsRemaining.toString().padStart(2, '0');
    const label = `${prefix}${h}:${m}:${s}`;

    return { h, m, s, label, prefix, klass };
  }

  public minsSecsFrom(d1: Date, d2?: Date) {
    d1 = this.dateService.getDate(d1);
    d2 = this.dateService.getDate(d2);
    const msecs = d1.getTime() - d2.getTime();
    const secsAgo = msecs / 1000;
    const secondsAgo = Math.round(secsAgo);
    return this.hoursMinSecsAgo(secondsAgo);
  }

  public static reload(isElectron: boolean) {
    if (isElectron) {
      // note using window.require to avoid webpack inclusion
      // https://github.com/electron/electron/issues/7300
      const remote = window['require']('electron').remote;
      const filePath = remote.getGlobal('filePath');
      // reload the original path (index.html), not reload() which didn't work correctly
      remote.getCurrentWindow().loadURL(filePath);
    } else {
      if (window.cordova) {
        window.location.href = 'index.html';
      } else {
        window.location.href = '/';
      }
    }
  }
}
