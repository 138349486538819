import { Injectable } from '@angular/core';

import build from '../../build-info';
import pkg from '../../../package.json';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class Config {
  public settings = environment;
  public package = pkg;
  public build = build;
  public appName: string;
  public appTitle: string;
  public version: string;
  public buildNum: string;
  public getAppName() {
    return this.build.appName;
  }

  public get oauth2() {
    return environment.oauth2['shotspotter'][this.build.platform];
  }

  public get isDevelopmentEnv() {
    const subdomain = window.location.hostname.split('.')[0];
    return this.build.env === 'dev' || this.build.env === 'vmdev' || subdomain === 'localhost';
  }

  public get isDevOrIntEnv() {
    return this.isDevelopmentEnv || (this.build.env === 'int');
  }

  public get environmentHost() {
    // For consistency across apps and platforms, use svc endpoint only
    // instead of proxying svc through the app domain
    return environment.svcHost;
  }

  constructor() {
    this.appName = this.getAppName();
    this.appTitle = 'ShotSpotter';

    const versionBuild = this.package.version.split('+');
    this.version = versionBuild[0];
    this.buildNum = versionBuild[1] || this.build.version.split('+')[1];
    this.build.version = this.package.version;
  }

  public getServiceUrl(serviceName: string) {
    // supports ability to specify a full http path in the configured value
    // else it uses environmentHost prefix
    const endpoint = environment.endpoints[serviceName];
    if (!endpoint) {
      throw new Error('invalid endpoint name: ' + serviceName);
    }
    let prefix = this.environmentHost;
    if (endpoint.indexOf('http') === 0) {
      prefix = '';
    }
    if (endpoint.indexOf('.json') !== -1) {
      prefix = '';
    }
    return prefix + endpoint;
  }
}

