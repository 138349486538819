import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, share, tap } from 'rxjs/operators';
import { Config } from '../framework/config';
import { ISettingDTO } from './ISettingDTO';
import { RestService } from './rest.service';

@Injectable({
  providedIn: 'root'
})
export class SystemSettingsService {
  public settings = new Map<string, any>();
  constructor(
    private config: Config,
    private restService: RestService
  ) { }
  public getSystemSettings(): Observable<ISettingDTO[]> {
    const url =
      this.config.getServiceUrl('settings-service') + 'system-setting/RespondApp';
    return this.restService.getFromAuthenticatedEndpoint(url).pipe(
      map(res => ((res.data ? res.data : res) as ISettingDTO[])),
      tap(res => {
        res.forEach(setting => {
          this.settings.set(setting.key, setting.value);
        });
      }),
      share()
    );
  }
}

