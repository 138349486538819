import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import Debug from 'debug';

const debug = Debug('sst:offline-status');

@Injectable({
  providedIn: 'root'
})
export class OfflineStatusService {
  private _socketIsOffline = true;
  public get socketIsOffline() {
    if (localStorage.offline) {
      return true;
    }
    return this._socketIsOffline;
  }
  public set socketIsOffline(val) {
    if (localStorage.offline) {
      val = true;
    }
    if (!navigator.onLine) {
      // this make debugging work (network override doesn't affect socket connection)
      // so you can emulate offline
      val = true;
      // this still allows the app to respond to dropped socket connection even if network is available.
      // as we don't override if network is available.
    }
    this._socketIsOffline = val;
    this.setOffline(val);
  }

  public isOffline = !navigator.onLine;
  public isOffline$ = new BehaviorSubject<boolean>(!navigator.onLine);

  constructor() {
    window.addEventListener('online', () => {
      this.setOffline(false);
    });
    window.addEventListener('offline', () => {
      this.setOffline(true);
    });
  }

  private setOffline(val: boolean) {
    // debug('setOffline', val);
    const current = this.isOffline;
    this.isOffline = val || this.socketIsOffline;
    if (current !== this.isOffline) {
      debug('offline changed', this.isOffline);
      this.isOffline$.next(this.isOffline);
    }
  }
}
