import merge from 'lodash-es/merge';

import { environment as base } from './environment.base';
import buildInfo from '../build-info';

const domain = (
  // can't use window.location on mobile and legacy electron
  ['android', 'ios'].includes(buildInfo.platform) ||
  buildInfo.platform === 'electron' && !window.sstElectronApi
) ?
  'soundthinking.com' :
  window.location.hostname.split('.').slice(-2).join('.');

const electronScheme = window.sstElectronApi ?
  window.sstElectronApi.build.appId :
  buildInfo.platform === 'electron' && window['require']('electron').remote.getGlobal('appId');

export const environment = merge(base, {
  envName: 'prod',
  loadFullStory: false,
  heapKey: '310826505',
  googleAPIKey: 'AIzaSyAzAGuD-QnI2uQW2d9ikV-uMzd9I3g8IPo',
  svcHost: `https://svc.${domain}`,
  // For legacy electron self-update
  updateSourceUrl: 'https://shotspotter.shotspotter.com/auto-update2',
  oauth2: {
    shotspotter: {
      android: {
        clientId: 'soundthinking.shotspotter.android.35d727a8835ba85f',
        logoutRedirectUri: 'com.shotspotter.alerts://sso/logout-redirect',
        openIdConnectUrl: `https://hydra.${domain}`,
        redirectUri: 'com.shotspotter.alerts://sso/login-redirect'
      },
      electron: {
        clientId: 'soundthinking.shotspotter.electron.9a164435b1826861',
        logoutRedirectUri: `${electronScheme}://sso/logout-redirect`,
        openIdConnectUrl: `https://hydra.${domain}`,
        redirectUri: `${electronScheme}://sso/login-redirect`
      },
      ios: {
        clientId: 'soundthinking.shotspotter.ios.e9606b865c2f1177',
        logoutRedirectUri: 'com.shotspotter.alerts://sso/logout-redirect',
        openIdConnectUrl: `https://hydra.${domain}`,
        redirectUri: 'com.shotspotter.alerts://sso/login-redirect'
      },
      web: {
        clientId: 'soundthinking.shotspotter.web.bdd3bcb45f2559c6',
        logoutRedirectUri: `https://${window.location.hostname}/logout-redirect`,
        openIdConnectUrl: `https://hydra.${domain}`,
        redirectUri: `https://${window.location.hostname}/login-redirect`
      }
    }
  },
  production: true,
  sentry: {
    android: 'https://04c8e8c2fc8f4ce4a6678b8fd02c4bd6@sentry.io/120142',
    electron: 'https://b82dfa8b435f4fe09562181bf3a6898e@sentry.io/171441',
    ios: 'https://3570becbb38b488b8adb40073677036d@sentry.io/120141',
    web: 'https://6384ce1200214a0cbc35a205ed08b4d5@sentry.io/120140'
  }
});
