import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgForage } from 'ngforage';
import { Observable, throwError } from 'rxjs';

import { UtilService } from '../framework/dom.util';
import { DateService } from '../services/date.service';
import { cloneDeep } from 'lodash';
import { TranslateService } from '@tolgee/ngx';

const CACHE_NAME = 'http.cache';

const englishPhrases = {
  'rest.service.server-timeout-reached': 'Server timeout reached, please try again.',
  'rest.service.unable-to-connect': `Unable to connect to a ShotSpotter server.
 Please check your network.`,
  'rest.service.unable-to-connect-mobile': `Unable to connect to a ShotSpotter server.
 Please check the network settings on your device.
 Mobile devices may have cellular data access restricted per application.`

};

const phrases = cloneDeep(englishPhrases);

@Injectable({
  providedIn: 'root'
})
export class RestService {
  private cache;
  constructor(private ngForage: NgForage,
              private dateService: DateService,
              private translateService: TranslateService,
              private http: HttpClient) {
    /*
    */
    this.cache = this.ngForage;

    Object.keys(englishPhrases).forEach(k => {
      this.translateService.translate(k, englishPhrases[k])
        .subscribe(v => {
          phrases[k] = v;
        });
    });
  }

  public isLoggedOut = false;

  public clearCache() {
    // caution: hack here, due to bug with ngForage.clone not working well on ios
    // (any uses of the instance throw an error)
    //
    // changing name does force it to use a new store instance
    // but we need to reset it so other uses of the store aren't affected by this clear()
    // method.
    //
    // We could maybe improve this by wrapping all of this into our own 'storage' object
    // with get and set methods that correctly set the name property, or just uses localForage
    // directly...
    //
    const oldname = this.cache.name;
    this.cache.name = CACHE_NAME;
    const result = this.cache.clear();
    this.cache.name = oldname;
    return result;
  }

  public postToEndpoint(url, payload, errHandler?) {
    return this.performHTTPRequest('post', url, payload, errHandler);
  }

  public performHTTPRequest(method, url, payload?, errHandler?) {
    if (this.isLoggedOut) {
      // shouldn't be possible.
      return throwError('not logged in');
    }
    const options = {
      headers: this.setHeaders('application/json')
    };

    let request: Observable<any>;
    method = method.toLowerCase();
    switch (method) {
    case 'post':
      request = this.http.post<any>(url, payload, options);
      break;
    case 'get':
      request = this.http.get<any>(url, options);
      break;
    case 'patch':
      request = this.http.patch<any>(url, payload, options);
      break;
    case 'put':
      request = this.http.put<any>(url, payload, options);
      break;
    case 'delete':
      request = this.http.delete<any>(url, options);
      break;
    default:

      // i18n only in dev
      throw new Error('unsupported http method type: ' + method);
    }

    return request
      .catch(err => {
        return errHandler ? errHandler(err) : this.onServiceError(err, url, method);
      });
  }

  public setHeaders(contentType): HttpHeaders {
    const headers = {
      'Content-Type': contentType
    };

    return new HttpHeaders(headers);
  }

  public getFromAuthenticatedEndpoint(url) {
    return this.performHTTPRequest('get', url)
      .map((res: any) => {
        let result;
        if (res) {
          result = res.data || res;
          const oldname = this.cache.name;
          this.cache.ready().then(() => {
            this.cache.name = CACHE_NAME;
            this.cache.setItem(this.getCacheKey(url), result)
              .catch(err => {
                console.warn('error saving to cache', err);
                console.log('key, value', this.getCacheKey(url), result);
              });
            this.cache.name = oldname;
          });
        } else {
          console.warn('no result for ', url);
          result = res;
        }
        return result; // handle non JSend responses too
      }); // don't do generic catching here, it's done in performHTTPRequest
  }

  private onServiceError(response: any, url: string, method: string) {
    // if (response?.error?.httpCode)
    let message;
    if (response.json) {
      try {
        const body = response.json();
        console.log('err response json', body);
        if (body.message) {
          message = body.message;
        }
        if (body.stack && body.stack.includes('Error: Timeout')) {
          message = phrases['rest.service.server-timeout-reached'];
        }
      } catch (jsonErr) {
        console.warn('no valid json in response', jsonErr);
      }
    }
    if (!message && typeof response.error === 'string') {
      message = response.error;
    }
    console.log('err response', response.error);
    if (!message && response.error.error && response.error.error.stack &&
        response.error.error.stack.includes('Error: Timeout')) {
      message = phrases['rest.service.server-timeout-reached'];
    }
    // a bit hacky, but we're getting error messages in a few different properties now...
    message = message ||
      (response.error?.text) ||
      (response.error?.message) ||

      (response.error?.messages?.join(',')) ||

      (response.error?.error?.text) ||
      (response.error?.error?.message);

    if (message) {
      throw new Error(message);
    }
    // default message(s)
    message = phrases['rest.service.unable-to-connect'];

    if (UtilService.isMobile) {
      message = phrases['rest.service.unable-to-connect-mobile'];
    }
    if ((!response.status) && (method === 'get')) {
      return this.handleOfflineResponse(url, message);
    }

    throw new Error(message);
  }

  private getCacheKey(url) {
    const search = 'incidentservices/?startDateTime=';
    const index = url.indexOf(search);
    // special case for incidentServices timestamps
    // change them to /<#days> for simplified caching
    // for offline cases
    if (index !== -1) {
      const dateString = url.substr(index + search.length).split('&')[0];
      const theTime = this.dateService.getDate(dateString).getTime();
      const msAgo = this.dateService.getDate().getTime() - theTime;
      const msPerDay = (1000 * 60 * 60 * 24);
      const daysAgo = Math.floor(msAgo / msPerDay);

      url = url.substr(0, index) + 'incidentservices/' + daysAgo;
    }

    // console.log('url', url);
    return url;
  }

  private handleOfflineResponse(url, message) {
    // check cache
    return this.cache.ready().then(() => {
      const key = this.getCacheKey(url);
      const oldName = this.cache.name;
      this.cache.name = CACHE_NAME;
      const promise = this.cache.getItem(key).then(result => {
        if (!result) {
          throw new Error(message);
        }

        // console.log('found value in cache', result);

        return result;
      }).catch(err => {
        console.error('error using cache', url, err);
        throw new Error(message);
      });

      this.cache.name = oldName;

      return promise;
    });
  }
}
