import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';

import { Config } from '../framework/config';
import { UserService } from '../services/user.service';

interface IFileEntry {
  path: string;
  md5: string;
}

@Injectable({
  providedIn: 'root'
})
export class VersionUpdateService implements CanActivate, CanLoad {
  public skipUpgrade = false;
  public appData;

  private versionUrl;
  private newVersion;
  private total;
  private count;
  private electron: any;
  private fs: any;
  private md5: any;
  private baseUrl;

  constructor(private http: HttpClient,
              private config: Config,
              private router: Router,
              private userService: UserService) {
    // note using window.require to avoid webpack inclusion
    // https://github.com/electron/electron/issues/7300
    //
    // This will be undefined in new electron wrapper (when window.sstElectronApi is defined)
    // This self-update logic only applies to legacy electron wrapper (file://)
    this.electron = (config.build.platform === 'electron' && !window.sstElectronApi) ?
      window['require']('electron') :
      undefined;

    if (!this.electron) {
      this.skipUpgrade = true;
      return;
    }

    this.fs = this.electron.remote.require('fs-extra');
    this.md5 = this.electron.remote.require('md5');

    const now = Date.now();
    this.baseUrl = config.settings.updateSourceUrl;
    this.versionUrl = `${this.baseUrl}/${config.build.platform}/version.txt?v=${now}`;
    // this.userService.loggedOut$.subscribe((_) => {
    //   this.skipUpgrade = false;
    // });

    this.appData = this.electron.remote.app.getPath('userData');
  }

  public canActivate() {
    return this.canActivateOrLoad();
  }

  public canLoad() {
    return this.canActivateOrLoad();
  }

  public updateSoftware = progressCB => {
    if (!this.newVersion) {
      // user navigating directly to the update somehow
      // just bail.
    }
    const now = Date.now();
    const manifestUrl = `${this.baseUrl}/${this.config.build.platform
    }/${this.newVersion}/asset_manifest_md5.json?v=${now}`;

    return window.fetch(manifestUrl).then(response => {
      return response.json().then((manifest: any) => {
        console.log('asset_manifest', manifest);
        return manifest.files;
      });
    }).then(filesInNewVersion => {
      console.log('about to to update software');
      // baseUrl includes version number
      // so that we intentionally 404
      // if the server doesn't have that version deployed
      // in-flight multi-server deployments may have version.txt requested
      // from an updated server while other requests may be served from no-yet-updated servers
      const baseUrl = `${this.baseUrl}/${this.config.build.platform}/${this.newVersion}/`;

      // filter only files that don't match md5 hashes (only changed files)

      // for ease of lookup , use hash
      const newMd5s: any = {};

      filesInNewVersion.forEach(file => {
        newMd5s[file.path] = file.md5;
      });

      const promises = filesInNewVersion.map(file => {
        // read current file
        const path = `${this.appData}/app/${file.path}`;
        const exists = this.fs.existsSync(path);
        if (!exists) {
          // new file
          return { path: file.path, md5: undefined } as IFileEntry;
        }
        return this.fs.readFile(path).then(data => {
          return { path: file.path, md5: this.md5(data) } as IFileEntry;
        });
      });

      return Promise.all(promises).then(existingMd5s => {
        // list of new files with existing md5 hashes
        // hash is null for new files

        return existingMd5s.filter((existingMd5: IFileEntry) => {
          // fash hash lookup
          return existingMd5.md5 !== newMd5s[existingMd5.path];
        });
      }).then(filesToDownload => {
        // here we only have the new or changed files

        this.count = 0;
        this.total = filesToDownload.length;

        const downloads = filesToDownload.map((file: IFileEntry) => {
          const url = baseUrl + file.path;
          return this.downloadFile(url, file.path, progressCB);
        });

        return Promise.all(downloads);
      }).then(filesDownloaded => {
        console.log(`downloaded ${filesDownloaded.length} files, copying new version`);
        // note, can't easily use fs.move(sourceDir, parentDir);
        // so we'll just use the application manifest for the
        // list of files to move
        //
        // move all files
        const moves = filesDownloaded.map(file => {
          const source = `${this.appData}/${this.newVersion}/${file}`;
          const dest = `${this.appData}/app/${file}`;

          console.log('moving source, dest', source, dest);
          return this.moveFile(source, dest);
        });

        return Promise.all(moves).then(filesMoved => {
          console.log('all files moved');
          return filesMoved;
        });
      });
    });
  };

  private canActivateOrLoad() {
    // console.log('versionupdate canactivate?\n\n\n\n\n');

    return this.skipUpgrade || !navigator.onLine || new Promise(resolve => {
      // set skipUpgrade while we're waiting for the check to complete
      // so multiple executions of this guard won't all trigger http requests
      this.skipUpgrade = true;

      this.http.get(this.versionUrl, { responseType: 'text' })
        .subscribe(
          newVersion => {
            // NOTE: newVersion actually contains the git commitHash
            // changed from version number due to inabilty to control the jenkins build number
            window.setTimeout(() => {
              this.newVersion = newVersion;
              resolve(newVersion);
            }, 3000);
          },
          err => {
            console.error('error checking version', err);
            // don't fail the navigation, just let them in without upgrade
            resolve(this.config.version);
          }
        );
    }).then(newVersion => {
      console.log('installed commitHash', this.config.build.commitHash);

      console.log('latest commitHash @ ' + this.versionUrl, newVersion);
      if (newVersion === this.config.build.version) {
        // we couldn't get the latest available version info
        // just let them in.
        this.skipUpgrade = true;
        return true;
      }

      if (this.config.build.commitHash === newVersion) {
        return true;
      }

      this.skipUpgrade = false;
      this.router.navigate(['/software-update']);
      return false;
    });
  }

  private moveFile = (source, destination, tries = 20) => {
    const opts = { overwrite: true };
    return this.fs.move(source, destination, opts)
      .then(() => {
        return destination;
      })
      .catch(err => {
        if (!tries) {
          throw err;
        }
        console.warn(`problem moving file to ${destination}; ${tries} attempts remaining`);
        tries -= 1;
        return new Promise(resolve => {
          // small pause, then retry
          window.setTimeout(() => {
            resolve(this.moveFile(source, destination, tries));
          }, 500);
        });
      });
  };

  private checkV1Download() {
    if (localStorage.v1DownloadFixed !== '1') {
      // get md5 of current unhandled-incidents.mp3 file.
      const path = this.appData + '/app/assets/sounds/unhandled-incidents.mp3';
      console.log('path', path);
      const mp3md5 = this.md5(this.fs.readFileSync(path));
      console.log('md5', mp3md5);
      if (mp3md5 !== 'e6c30481f2c676a3e6daccd6fec5848f') {
        return false;
      }
      localStorage.v1DownloadFixed = '1';
    }

    return true;
  }

  private downloadFile = (url, filename, cb) => {
    return window.fetch(url).then(response => {
      if (!response.ok) {
        throw new Error(`unable to download ${filename} - status code: ${response.status}`);
      }

      // user arrayBuffer to handle binary files as well.
      return response.arrayBuffer().then(arrayBuffer => {
        const buffer = new Buffer(arrayBuffer);
        const filePath = `${this.appData}/${this.newVersion}/${filename}`;
        return this.fs.outputFile(filePath, buffer);
      }).then(() => {
        // console.log(`finished writing ${filename}`);
        cb({ total: this.total, count: this.count++ });

        return filename;
      });
    });
  };
}
