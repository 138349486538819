import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';

import { Browser } from './browserInfo';

@Injectable({
  providedIn: 'root'
})
export class BrowserGuard implements CanActivate, CanLoad {
  constructor(private browser: Browser, private router: Router) {/* placeholder */}

  public canActivate() {
    return this.canActivateOrLoad();
  }

  public canLoad() {
    return this.canActivateOrLoad();
  }

  private canActivateOrLoad() {
    // console.log('guard.canActivate', this.browser.isValid);
    if (this.browser.isValid) {
      // allow navigation to continue
      return true;
    } else {
      // redirect to supported-browsers
      this.router.navigate(['/supported-browsers']);
      return false;
    }
  }
}
