import { Injectable } from '@angular/core';
import * as Debug from 'debug';

const debug = Debug('sst:browserInfo');
@Injectable({
  providedIn: 'root'
})
export class Browser {
  /* based on the popular SO anser:
   * http://stackoverflow.com/a/2401861/521042
   * modified to handle opera and return an object rather than a string
   */
  public info = (() => {
    // tslint:disable-next-line
    const ua = navigator.userAgent; let tem;
      let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return {
        name: 'MSIE',
        version: (tem[1] || '')
      };
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) { // eslint-disable-line
        tem[0] = tem[0].split('/')[1];
        return {
          name: tem[1].replace('OPR', 'Opera'),
          version: tem[0]
        };
      }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    // tslint:disable-next-line
    if ((tem = ua.match(/version\/(\d+)/i)) != null) { // eslint-disable-line
      M.splice(1, 1, tem[1]);
    }
    return {
      name: M[0],
      version: M[1]
    };
  })();

  public supportedBrowsers = [
    { name: 'Chrome', version: 51 },
    { name: 'Firefox', version: 45 },
    { name: 'Safari', version: 9.0 },
    { name: 'MSIE', version: 11 },
    { name: 'Edge', version: 14 },
    { name: 'Opera', version: 35 }
  ];

  public found = this.supportedBrowsers.find(b => {
    return (b.name === this.info.name) && (b.version <= this.info.version);
  });

  public isValid = !!(window['cordova'] || this.found);

  constructor() {
    debug('Detected Browser', this.info);
  }
}
