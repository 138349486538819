import { RouterModule } from '@angular/router';
import { AuthGuard, LoginComponent } from '@sst-inc/angular-auth';

import { BrowserGuard } from './supportedBrowsers/guard.service';
import { SupportedBrowsersComponent } from './supportedBrowsers/supportedBrowsers.component';
import { VersionUpdateService } from './versionUpdate/versionUpdate';
import { VersionUpdateComponent } from './versionUpdate/versionUpdate.component';

export const appRoutes = RouterModule.forRoot([
    {
        canLoad: [BrowserGuard, VersionUpdateService, AuthGuard],
        loadChildren: () => import('./main/main.module').then(m => m.MainModule),
        path: 'main'
    },
    {
        component: LoginComponent,
        path: 'login-redirect'
    },
    {
        component: LoginComponent,
        path: 'logout-redirect'
    },
    {
        component: LoginComponent,
        path: 'silent-refresh'
    },
    {
        component: SupportedBrowsersComponent,
        path: 'supported-browsers'
    },
    {
        component: VersionUpdateComponent,
        path: 'software-update'
    },
    {
        component: VersionUpdateComponent,
        path: 'software-update/:force'
    },
    // default route
    {
        path: '**',
        pathMatch: 'full',
        redirectTo: 'main'
    }
] /* , {
  enableTracing: true,
}*/, { relativeLinkResolution: 'legacy' });
