export class IncidentSubscription {
  public entityId: number;
  public entityType: string;
  public isSubscribed: boolean;
  public displayName: string;
  constructor(obj?: any) {
    if (obj) {
      Object.keys(obj).forEach(key => {
        switch (key) {
        case 'updatedOn':
        case 'createdOn':
          this[key] = obj[key] ? new Date(obj[key]) : obj[key];
          break;

        default:
          this[key] = obj[key];
        }
      });
    }
  }
}
