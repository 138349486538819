export class User {
  public agency: any;
  public agencyId: number;
  public allowedActions: string[];
  public originalAgency?: any; // when in "view as agency" mode
  public originalAgencyId?: number; // when in "view as agency" mode
  public createdBy: string;
  public createdAt: Date;
  public email: string;
  public groups: string[];
  public firstName: string;
  public lastName: string;
  public fullName: string;
  public id: string;
  public intercomHash: string;
  public isActive: boolean;
  public isAvailable = false;
  public lastAcceptedOn: Date;
  public lastAssignedOn: Date;
  public lastCompletedOn: Date;
  public maxAcceptedTasks = 6;
  public mobileNumber: string;
  public phoneNumber: string;
  public numAcceptedTasks: number;
  public permissions: any;
  public rankName: string;
  public roles: string[];
  public rolesDetails: {id: string, displayName: string}[];
  public status: string;
  public subscriptions: any[];
  public title: string;
  public updatedBy: string;
  public updatedAt: Date;
  public userName: string;
  public version: number;

  constructor(obj?) {
    if (obj) {
      Object.keys(obj).forEach(key => {
        switch (key) {
        case 'username':
          this.userName = obj[key];
          break;
        case 'lastAcceptedOn':
        case 'lastAssignedOn':
        case 'lastCompletedOn':
        case 'updatedAt':
        case 'createdAt':
          this[key] = obj[key] ? new Date(obj[key]) : obj[key];
          break;

        default:
          this[key] = obj[key];
        }
      });
      this.fullName = `${this.firstName} ${this.lastName}`;
    }
  }
  public getAgencyInfo() {
    // When in "view as agency" mode, keep sending the original agency info
    // to avoid polluting data of the chosen agency
    const agencyId =
      typeof this.originalAgencyId === 'number' ?
        this.originalAgencyId :
        this.agencyId;
    const agencyName =
      (this.originalAgency && this.originalAgency.name) ||
      (this.agency && this.agency.name);
    const agencyCreatedAt =
      (this.originalAgency && this.originalAgency.createdAt) ||
      (this.agency && this.agency.createdAt);
    return {
      agencyId,
      agencyName,
      agencyCreatedAt
    };
  }
}
