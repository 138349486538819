import { EventEmitter, Injectable } from '@angular/core';
import * as Debug from 'debug';
import { Subject } from 'rxjs';
import * as wait from 'wait-promise';

import { Config } from '../../framework/config';
import { UtilService } from '../../framework/dom.util';
import { ServiceWorkerService } from '../../services/serviceWorker.service';
import { SessionEventService } from '../../sessionEvents/sessionEvent.service';
import { SubscriptionService } from './subscription.service';

const debug = Debug('sst:browserPush.service');
@Injectable({
  providedIn: 'root'
})
export class BrowserPushService {
  public browserPushIncidentClicked = new EventEmitter();
  public registrationId: string;
  public ready = new EventEmitter();
  public endpoint: string;
  private supported = false;
  private events = new Map<string, Subject<any>>();

  constructor(private serviceWorkerService: ServiceWorkerService,
              private config: Config,
              private subscriptionService: SubscriptionService,
              private sessionEventService: SessionEventService
  ) {
    if (serviceWorkerService.supported && 'PushManager' in window && 'Notification' in window) {
      // debug('Notification and Push is supported');
      if (window['cordova'] || this.config.build.platform === 'electron') {
        debug('Web Push Notifications not supported on this platform (cordova || electron).');
        return;
      }
      this.supported = true;

      this.initCustomMessages();
    } else {
      debug('Web Push Notifications not supported on this platform.');
    }
  }

  public subscribe = () => {
    if (!this.supported) {
      return Promise.resolve('Cannot subscribe - Push Notifications not supported on this platform');
    }
    return this.serviceWorkerService.getRegistration()
      .then(reg => {
        // trigger queued messages
        debug('triggering queued messages');
        this.serviceWorkerService.sendMessageToSW({
          eventName: 'sendQueuedMessages'
        }).then(result => {
          debug('succesfully requested queued messages from SW');
        }).catch(err => {
          const message = 'error initializing service worker';
          console.error(message, err);
          UtilService.captureMessage(message, err);
        });

        return reg;
      })
      .then(this.getPushSubscription)
      .then(this.registerWithServer)
      .catch(error => {
        // https://github.com/Microsoft/TypeScript/issues/14701
        // bug in typing for Notification
        if (Notification['permission'] === 'denied') {
          debug('browser push notification permission denied');
          return;
        }
        debug('Notification.permission', Notification['permission']);
        const message = 'Service Worker Error';
        console.error(message, error);
        UtilService.captureMessage(message, error);
      });
  };

  public unsubscribe = (): any => {
    if (!this.supported) {
      return Promise.resolve('Cannot unsubscribe - Push Notifications not supported on this platform');
    }

    /*
     * note, this just unsubscribes the current user from the push notification services.
     * it does not send a message to our own subscription service - the logout event on the server takes care of that.
     *
     * it's possible for the user to be unsubscribed here but perhaps the server did not receive the logout event
     * this means that the our server's subscription may be invalid.
     *
     * I believe this is OK - the server will need to handle this case in any event.
     *
     * todo? check if user is subscribed by checking this.endpoint???
     *
     */

    return this.serviceWorkerService.getRegistration()
      .then(registration => {
        return registration.pushManager.getSubscription();
      })
      .then(subscription => {
        if (!subscription) {
          debug('no browser push notification subscription found');
          return;
        }

        return subscription.unsubscribe();
      })
      .then(success => {
        // clear this regardless of result.
        this.endpoint = '';

        if (!success) {
          // user may have had no subscription
          return 'no subscription to unsubscribe from';
        }

        debug('successfuly unsubscribed from browser push notification');
        return 'success';
      })
      .catch(err => {
        const message = 'error unsubscribing from browser push notifications';
        console.error(message, err);
        UtilService.captureMessage(message, err);
      });
  };

  public fromEvent(eventName: string): Subject<any> {
    if (!this.events.has(eventName)) {
      this.events.set(eventName, this.makeEventSubject());
    }
    const eventSubject = this.events.get(eventName);

    return eventSubject;
  }

  private urlB64ToUint8Array = base64String => {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  };

  private registerWithServer = (pushSubscription): Promise<any> => {
    // debug('pushSubscription :', pushSubscription);
    if (!pushSubscription) {
      debug('no push subscription');
      return Promise.resolve('no push subscription');
    }

    const json = JSON.stringify(pushSubscription);
    const objPush = JSON.parse(json);

    const payload = {
      devicetoken: objPush.endpoint,
      keys: objPush.keys,
      sstBuild: this.config.buildNum,
      sstVersion: this.config.version,
      type: 'web',
      userAgent: navigator.userAgent
    };
    // debug('browser push registration payload :', payload);
    return this.subscriptionService.registerDevice(payload)
      .then(result => {
        const alreadyReady = !!this.registrationId;
        this.registrationId = objPush.endpoint;
        if (!alreadyReady) {
        // listeners to this set up event handlers, don't do that more than once.
          this.ready.emit();
        }
        return {
          notificationSubscription: result,
          pushSubscription,
          pushSubscriptionJson: json,
          pushSubscriptionObj: objPush
        };
      });
  };

  private initCustomMessages = () => {
    // messages from sw.postMessage

    navigator.serviceWorker.addEventListener('message', e => {
      // messages from service worker.
      const message = e.data;

      switch (message.event) {
      case 'browserPushNotificationClicked':

        this.sessionEventService.addEvent('ui.browser-push-notification-clicked', {
          notificationId: message.data && message.data.notificationId,
          sourceIncidentId: message.data && message.data.deliveredData && message.data.deliveredData.sourceIncidentId
        }
        );

        switch (message.data.eventType) {
        case 'incident_published': {
          const incident = message.data.deliveredData;
          if (!incident) {
            return;
          }
          if (!incident.sourceIncidentId) {
            debug('invalid incident', incident);
            return;
          }
          this.browserPushIncidentClicked.emit(incident);

          break;
        }
        case 'message_added':
        case 'message_updated':
          // do nothing for now; messages will be displayed in UI.
          // in the future we might highlight the message,
          // or expand a message or animate something.

          break;
        }

        break;

      case 'browserPushReceived': {
        const eventName = message.data.eventType;
        wait.every(100, 60) // wait for up to 1 minute
          .until(() => this.events.has(eventName))
          .then(() => {
            this.logPossibleLateDelivery(message);

            const evt = this.events.get(eventName);
            evt.next(message.data);
          });
        break;
      }
      case 'suppressedPushNotification':

        this.sessionEventService.addEvent('ui.browser-push-notification-suppressed', {
          notificationId: message.data && message.data.notificationId,
          sourceIncidentId: message.data && message.data.deliveredData && message.data.deliveredData.sourceIncidentId
        }
        );

        break;
      }
    });
  };

  private logPossibleLateDelivery(message) {
    // only log late deliveries for incident_published
    if (message.data.eventType === 'incident_published') {
      // base this check on createdOn time rather than triggerDateTime,
      // to allow for review delays.
      // we're only measuring transport time delays here.
      const created = new Date(message.data.createdOn).getTime();
      const now = Date.now();
      const difference = now - created;
      const mins = Math.round(difference / 60000);
      const isLate = (mins > 1);

      if (isLate) {
        debug('browser push received mins after sending', mins);
        this.sessionEventService.addEvent('ui.browser-push-notification-late-delivery', {
          delayMins: mins,
          notificationCreatedOn: message.data.createdOn,
          notificationId: message.data.notificationId,
          receivedOn: now,
          sourceIncidentId: message.data.deliveredData && message.data.deliveredData.sourceIncidentId
        }
        );
      }
    }
  }

  private getPushSubscription = registration => {
    // tell service worker they can start listening for push notifications
    // (only for non-cordova clients)
    if (!this.supported) {
      return;
    }

    return registration.pushManager.getSubscription()
      .then(subscription => {
        if (subscription) {
          this.endpoint = subscription.endpoint;
          return subscription;
        }

        // applicationServerKey could be provided via rest call or websocket api, or injected into the app js
        // injecting is simplest, but less secure...
        // tslint:disable-next-line
        const appServerPublicKey =
          'BINakKcE4L1vEHf1Fwo0FdGvpbA4b9JL7Wm8i3Mcz9UlYGd61lQ9UqZfzQKp2Kf19yae54nYJ6e_bT-UggLZ21A';
        const applicationServerKey = this.urlB64ToUint8Array(appServerPublicKey);
        // register for push
        return registration.pushManager.subscribe({
          applicationServerKey,
          userVisibleOnly: true
        }).then(sub => {
          this.endpoint = sub.endpoint;
          return sub;
        });
      });
  };
  private makeEventSubject(): Subject<any> {
    const eventSubject = new Subject();
    return eventSubject;
  }
}
