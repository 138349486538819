import { Injectable } from '@angular/core';
import * as Debug from 'debug';
import { NgForage } from 'ngforage';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';

import { Config } from '../framework/config';
import { cloneDeep } from 'lodash';
import { TranslateService } from '@tolgee/ngx';

const debug = Debug('sst:preferences.service');

interface ISetting {
  name: string,
  title: string,
  type: string,
  value: any;
  children: ISetting[]
  options: {title: string, value: string}[]
}

// note: if adding more settings, we must add new phrases...
const englishPhrases: { [key: string]: string } = {
  'preferences.UITheme': 'Theme',
  'preferences.UITheme.theme.dark': 'Dark',
  'preferences.UITheme.theme.light': 'Light',
  'preferences.login': 'Login',
  'preferences.login.keepMeSignedIn': 'Keep me signed in',
  'preferences.mapDisplay': 'Map',
  'preferences.mapDisplay.beat': 'Beat Boundaries',
  'preferences.mapDisplay.building': 'Building Outlines',
  'preferences.mapDisplay.coveragearea': 'Coverage Areas',
  'preferences.mapDisplay.district': 'District Outlines',
  'preferences.mapDisplay.useMyLocation': 'Show My Location',
  'preferences.measureTool': 'Distances',
  'preferences.measureTool.units.imperial': 'In Feet',
  'preferences.measureTool.units.metric': 'In Meters'
};

const phrases = cloneDeep(englishPhrases);

const settingsBykey: { [key: string]: ISetting } = {};

@Injectable({
  providedIn: 'root'
})
export class PreferencesService {
  public settings: any = {};
  public settingsMeta: any[] = [];
  public username: string;
  public settingsChanged = new Subject<{key: string, value: any}>();
  public ready$ = new BehaviorSubject<boolean>(false);
  public changesReady = new BehaviorSubject<boolean>(false);
  private langSubscription = new Subscription();

  // default settings are now only needed
  // for the v2 upgrade of user settings.
  // we can remove this once enough users have
  // migrated to v3 or greater (respond v 2.4)
  // tslint:disable:object-literal-sort-keys
  public defaultSettings: any[] = [
    {
      name: 'UITheme',
      title: 'Theme',
      type: 'section',
      children: [{
        disabled: false,
        hidden: false,
        name: 'theme',
        type: 'radio',
        value: 'dark',
        options: [
          {
            title: 'Light',
            value: 'light'
          },
          {
            title: 'Dark',
            value: 'dark'
          }
        ]
      }]
    },
    {
      name: 'mapDisplay',
      title: 'Map',
      type: 'section',
      children: [
        {
          name: 'coveragearea',
          title: 'Coverage Areas',
          type: 'checkbox',
          disabled: false,
          hidden: false,
          value: true
        },
        {
          name: 'district',
          title: 'District Outlines',
          type: 'checkbox',
          disabled: false,
          hidden: false,
          value: false
        },
        {
          name: 'beat',
          title: 'Beat Boundaries',
          type: 'checkbox',
          disabled: false,
          hidden: false,
          value: false
        },
        {
          name: 'building',
          title: 'Building Outlines',
          type: 'checkbox',
          disabled: true,
          hidden: true,
          value: false
        },
        {
          name: 'useMyLocation',
          title: 'Show My Location',
          type: 'checkbox',
          disabled: true,
          hidden: false,
          value: true
        }
      ]
    },
    {
      name: 'measureTool',
      title: 'Distances',
      type: 'section',
      children: [
        {
          name: 'units',
          type: 'radio',
          disabled: false,
          hidden: false,
          value: 'imperial',
          options: [
            {
              title: 'In Feet',
              value: 'imperial'
            },
            {
              title: 'In Meters',
              value: 'metric'
            }
          ]
        }
      ]
    },
    {
      name: 'login',
      title: 'Login',
      type: 'section',
      children: [
        {
          name: 'keepMeSignedIn',
          title: 'Keep me signed in',
          type: 'checkbox',
          disabled: false,
          hidden: false,
          value: false
        }
      ]
    },
    {
      name: 'privacy',
      title: 'Privacy',
      type: 'section',
      hidden: true,
      children: [
        {
          name: 'sessionEvents',
          title: 'Provide Quality Assurance Telemetry',
          type: 'checkbox',
          disabled: false,
          hidden: true,
          value: 'true'
        }
      ]
    },
    {
      name: 'incident',
      title: 'Incident Details',
      type: 'section',
      hidden: true,
      children: [
        {
          name: 'incidentReport',
          title: 'Allow Incident Report',
          type: 'checkbox',
          disabled: false,
          hidden: true,
          value: 'true'
        }
      ]
    }
  ];
  // tslint:enable:object-literal-sort-keys

  private userChanges: any = {};

  constructor(
    private config: Config,
    private storage: NgForage,
    private translateService: TranslateService
  ) {
    this.getData().then(data => {
      return this.loadSettings(data);
    }).then(() => {
      this.changesReady.next(undefined);
      this.changesReady.complete();
    }).catch(err => {
      console.error('error accessing user settings', err);
    });

    // useful for debugging
    window.sst.storage = storage;
  }

  public reset(key?: string, save = true) {
    if (!this.settingsMeta) {
      // should not be possible
      // should only be true if no logged in user
      console.warn('unable to reset user preferences, no defaults available');
      return Promise.resolve(undefined);
    }
    if (!key) {
      this.setDefaults(this.settingsMeta, true);

      if (save) {
        this.userChanges[this.username] = {};
      }
    } else {
      const change = this.userChanges[this.username][key];
      if (change) {
        delete this.userChanges[this.username][key];
        const settingDefault: any = this.getMetaSetting(this.settingsMeta, key);
        if (key in this.settings) {
          // ensure settingDefault actually exists
          // if not, set setting = undefined.

          this.settings[key] = settingDefault && settingDefault.value;
        }
      }
    }

    if (save) {
      return this.save();
    } else {
      return Promise.resolve();
    }
  }

  public setDefaults(settings: any[], skipUserChanges = false) {
    // settings from server
    // apply default values, in case server list is incomplete
    const tmp = JSON.parse(JSON.stringify(this.defaultSettings));
    tmp.forEach((s, index) => {
      const found = settings.find(v => v.name === s.name);
      if (found) {
        tmp[index] = found;
      }
    });

    settings = tmp;

    // now apply user specific settings.
    // user settings will prevail
    this.settingsMeta = settings;

    this.applyPlatformChanges();

    this.settings = this.getPathsV2(settings);

    this.setLanguage();

    if (!skipUserChanges) {
      this.applyUserChanges();
      this.ready$.next(true);
    }
  }

  public saveChange(key, value, skipEmit = false) {
    const changes = this.getUserChanges();

    const setting: any = this.getMetaSetting(this.settingsMeta, key);
    if ((!setting) || setting.disabled) {
      return;
    }

    // update settings object
    this.settings[key] = value;

    // update changes object
    changes[key] = value;

    // save changes object
    this.save().then(() => {
      if (!skipEmit) {
        this.settingsChanged.next({ key, value });
      }
    });
  }

  private save(suppressEmit?, toSave?) {
    if (!(this.userChanges || toSave)) {
      console.error('error saving settings; invalid settings');
      return Promise.reject(new Error('error saving settings'));
    }
    try {
      const serialized = JSON.stringify(toSave || this.userChanges);
      return this.storage.ready().then(() => {
        debug('save ready');
        return Promise.all([
          this.storage.setItem('ss_meta', serialized),
          this.storage.setItem('username', this.username),
          this.storage.setItem('metaVersion', '3')

        ]).catch(err => {
          console.error('error saving settings', err);
          console.log('settings', serialized);
          return [];
        });
      });
    } catch (err) {
      console.error('error serializing settings', err);
      console.log('settings', toSave, this.userChanges);
    }
  }

  private applyUserChanges() {
    if (!this.username) {
      return;
    }

    const changes = this.getUserChanges();

    Object.keys(changes).forEach(key => {
      const value = changes[key];
      // check if this setting is disabled
      const metaSetting = this.getMetaSetting(this.settingsMeta, key);
      if (metaSetting && (!metaSetting.disabled)) {
        // key is the path to the value eg: 'mapDetails.beats'
        this.settings[key] = value;
      } else {
        // setting disabled; reset any user changes
        if (metaSetting) {
          this.reset(key);
        }
      }
    });
  }

  private getMetaSetting(settings, key) {
    // pull off first part of key
    const [token, ...remaining] = key.split('.');
    if (!token) {
      console.error('token not found', key);
      return;
    }

    let child = settings.find(setting => {
      return setting.name === token;
    });

    if (remaining.length && child && child.children) {
      return this.getMetaSetting(child.children, remaining.join('.'));
    } else {
      if (!child) {
        // adding placeholder setting - for defaults
        child = { name: key };
        settings.push(child);
      }
      return child;
    }
  }

  private migrateV1(oldSettings): any {
    this.username = oldSettings.username;
    delete oldSettings.username;
    const newSettings = {};
    newSettings[this.username] = oldSettings;
    return newSettings;
  }

  private migrateV2(oldSettings): any {
    const changes = {};

    // this identifies the settings for this user
    // that are different than default
    // and stores those as changes.
    // going forward, a user's changes
    // will be stored and applied, even if they're the same
    // as the default setting (they were changed back to the default value)
    // we don't yet have the concept of the user
    // resetting a setting to the default value
    // which would allow the default value to be updated on the server
    // but it would be easy to implement by simply deleting the
    // this.userChanges[path] entry
    Object.keys(oldSettings).forEach(username => {
      // create list of differences between user settings and defaults
      const settings = oldSettings[username];
      const userPaths = this.getPaths(settings);

      // console.log('userpaths', userPaths);
      const defaults = this.getPathsV2(this.defaultSettings);
      // console.log('defaultpaths', defaults);
      const uniqueToUser = {};
      Object.keys(userPaths).forEach(path => {
        const userVal = userPaths[path];
        const defaultVal = defaults[path];
        if (userVal !== defaultVal) {
          uniqueToUser[path] = userVal;
        }
      });

      // console.log('unique to user' , uniqueToUser);
      changes[username] = uniqueToUser;
    });

    return changes;
  }

  private migrateV3(changes) {
    this.updateKey(changes, 'UITheme', 'UITheme.theme');
    this.updateKey(changes, 'keepMeSignedIn', 'login.keepMeSignedIn');

    return changes;
  }

  private updateKey(changes, key, newKey) {
    if (key in changes) {
      const val = changes[key];
      delete changes[key];
      changes[newKey] = val;
    }
  }

  private getPathsV2(settings, prefix = '') {
    // create a hash of paths
    // { 'mapDisplay.beats': true , 'mapDisplay.districts': false, etc}
    // from new schema
    const paths = {};

    settings.forEach(setting => {
      const name = setting.name;
      if (!name) {
        console.error('invalid setting', setting);
        throw new Error('invalid setting');
      }
      if (setting.children) {
        const childPaths = this.getPathsV2(setting.children, name);
        Object.keys(childPaths).forEach(childKey => {
          paths[name + '.' + childKey] = childPaths[childKey];
        });
      } else {
        paths[name] = setting.value;
      }

      setting.key = prefix ? prefix + '.' + name : name;

      // for easy lookup
      settingsBykey[setting.key] = setting;
    });

    return paths;
  }

  private getPaths(obj) {
    const paths = {};
    Object.keys(obj).forEach(key => {
      if (typeof obj[key] === 'object') {
        const childPaths = this.getPaths(obj[key]);
        Object.keys(childPaths).forEach(childKey => {
          paths[key + '.' + childKey] = childPaths[childKey];
        });
      } else {
        paths[key] = obj[key];
      }
    });

    return paths;
  }

  private getData(): Promise<any> {
    const username = localStorage.getItem('username');
    const ssmeta = localStorage.getItem('ss_meta');
    const metaVersion = localStorage.getItem('metaVersion');

    if (username) {
      // first time, insert into storage

      debug('first time, insert into storage');
      return this.storage.ready().then(() => {
        debug('ready');
        this.storage.setItem('username', username);
        this.storage.setItem('ss_meta', ssmeta);
        this.storage.setItem('metaVersion', metaVersion);

        localStorage.removeItem('username');
        localStorage.removeItem('ss_meta');
        localStorage.removeItem('metaVersion');

        return [username, ssmeta, metaVersion];
      });
    } else {
      return this.storage.ready().then(() => {
        return Promise.all([
          this.storage.getItem('username'),
          this.storage.getItem('ss_meta'),
          this.storage.getItem('metaVersion')
        ]);
      });
    }
  }

  private loadSettings(data) {
    // retrieve from storage
    const [username, meta, metaVersion] = data;
    this.username = username;
    let version;
    const changes = JSON.parse(meta);

    if (changes && (!metaVersion)) {
      try {
        if (changes.username) {
          version = 0;
        } else {
          version = 1;
        }
      } catch (ex) {
        console.error('error parsing settings', ex);
        return;
      }
    } else {
      version = metaVersion;
    }

    if (version && changes) {
      this.upgradeStoredSettings(changes, version).then(updatedChanges => {
        this.userChanges = updatedChanges;
        this.applyUserChanges();
      });
      // console.log('user changes', this.userChanges);
    } else {
      console.log('first-time initialization');
    }
  }

  private upgradeStoredSettings(changes, version) {
    if (version < 1) {
      changes = this.migrateV1(changes);
      version = 1;
    }

    if (version < 2) {
      changes = this.migrateV2(changes);
      version = 2;
    }

    if (version < 3) {
      changes = this.migrateV3(changes);
      return this.save(true, changes).then(() => {
        return changes;
      });
    }

    return Promise.resolve(changes);
  }

  private applyPlatformChanges() {
    let setting;
    if (this.config.build.platform !== 'electron') {
      setting = this.getMetaSetting(this.settingsMeta, 'login.keepMeSignedIn');
      setting.hidden = true;
      // hide the section header
      // perhaps this could be done generically for sections with no visible children
      setting = this.getMetaSetting(this.settingsMeta, 'login');
      setting.hidden = true;
    }

    const myLocationAllowed =
      (!!(localStorage.myLocationAvailable || window.cordova || (this.config.build.platform === 'electron')));
    setting = this.getMetaSetting(this.settingsMeta, 'mapDisplay.useMyLocation');
    if (!setting.value) {
      // option is turned off for the agency
      // leave it off
    } else {
      // option is enabled for the agency
      // that just means it's an option
      // now add platform rules.
      setting.value = myLocationAllowed;
    }
    // the above could be setting.value = setting.value && myLocationAllowed
    // but that's a bit too cryptic for me...

    setting.hidden = true;
  }

  private getUserChanges() {
    let changes = this.userChanges[this.username];
    if (!changes) {
      changes = this.userChanges[this.username] = {};
    }

    return changes;
  }

  private setLanguage() {
    if (this.langSubscription) {
      this.langSubscription.unsubscribe();
    }

    this.langSubscription = new Subscription();
    Object.keys(englishPhrases).forEach(k => {
      this.langSubscription.add(
        this.translateService.translate(k, englishPhrases[k])
          .subscribe(v => {
            phrases[k] = v;

            // these are the objects we're actually bound to.
            const key = k.replace('preferences.', '');
            const setting = settingsBykey[key];
            if (setting) {
              setting.title = v;
            } else {
            // might be options for parent setting
              const parts = key.split('.');
              const optionName = parts.pop();
              debug('option', optionName);
              const parentKey = parts.join('.');
              const parent = settingsBykey[parentKey];
              if (parent) {
                const found = parent.options?.find(i => i.value === optionName);
                if (found) {
                  found.title = v;
                }
              }
            }
          }));
    });
  }
}

