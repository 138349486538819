import './framework/debugTimer';
import {
  NgxTolgeeModule,
  Tolgee,
  TOLGEE_INSTANCE,
  FormatSimple,
  TranslateService,
  LanguageDetector,
  LanguageStorage,
  BackendFetch
} from '@tolgee/ngx';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, Injectable, NgModule, Provider } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import * as Sentry from '@sentry/angular';
import { AUTH_CONFIG, AuthGuard, AuthModule, AuthService, IAuthConfig, TokenService } from '@sst-inc/angular-auth';
import { ISocketConfig, SOCKET_CONFIG, SocketModule } from '@sst-inc/angular-socket';
import * as Debug from 'debug';
import { DEFAULT_CONFIG, NgForageOptions } from 'ngforage';

import { environment } from '../environments/environment';
import { appRoutes } from './app.routing';
import { closeBrowserTab, CordovaService, getReceivedCustomURL, openBrowserTab } from './cordova/cordova.service';
import { Config } from './framework/config';
import { IndexComponent } from './index.component';
import { InfoMessageComponent } from './infoMessage/info-message/info-message.component';
import { BrowserPushService } from './notification/services/browserPush.service';
import { PushService } from './notification/services/push.service';
import { SubscriptionService } from './notification/services/subscription.service';
import { DateService } from './services/date.service';
import { PreferencesService } from './services/preferences.service';
import { RestService } from './services/rest.service';
import { ServiceWorkerService } from './services/serviceWorker.service';
import { UIStateService } from './services/uiState.service';
import { UserService } from './services/user.service';
import { SessionEventService } from './sessionEvents/sessionEvent.service';
import { Browser } from './supportedBrowsers/browserInfo';
import { BrowserGuard } from './supportedBrowsers/guard.service';
import { SupportedBrowsersComponent } from './supportedBrowsers/supportedBrowsers.component';
import { VersionUpdateService } from './versionUpdate/versionUpdate';
import { VersionUpdateComponent } from './versionUpdate/versionUpdate.component';
import { LanguageInterceptor } from './languageInterceptor';
import { availableLanguages } from './availableLanguages';


// app.module.ts
const debug = Debug('sst:app.module');

/* tslint:disable:max-classes-per-file */

const aConfig = new Config();
@Injectable({
  providedIn: 'root'
})
export class SentryErrorHandler implements ErrorHandler {
  public handleError(err: any): void {
    const eventId = Sentry.captureException(err.originalError || err);
    console.error(err, eventId);
  }
}


// avoid calling 'getters' when processing the ngmodule directive
// explicitly set computed values here
const build = Object.assign({}, aConfig.build);
const config = Object.assign({}, {
  build,
  oauth2: aConfig.oauth2,
  appName: aConfig.appName,
  environmentHost: aConfig.environmentHost,
  userMeEndpoint: aConfig.getServiceUrl('user-service') + 'me'
});

debug('app version', config.build.version);
debug('git revision', config.build.commitHash);
debug('connected to', config.environmentHost);
const sentryUrl = environment.sentry && config.build.platform && environment.sentry[config.build.platform];

if (sentryUrl) {
  debug('configuring sentry: ' + sentryUrl, config.build.sentryVersion);
  Sentry.init({
    beforeSend: (e: Sentry.Event) => {
      // if /www/ does not exist (non-cordova), just return the actual filename
      const normalize = filename => {
        const www = filename.split('/www/', 2)[1];
        if (www) {
          return www;
        }
        const web = filename.split('//', 2)[1];
        if (web) {
          const file = web.split('/', 2)[1];
          return file;
        }
        return filename;
      };

      if (e) {
        e.exception?.values[0]?.stacktrace?.frames?.forEach(frame => {
          frame.filename = normalize(frame.filename);
        });

        // data.culprit = data.exception.values[0].stacktrace.frames[0].filename;
      }

      return e;
    },
    dsn: sentryUrl,
    environment: config.build.env,
    release: config.build.sentryVersion,
    initialScope: scope => {
      scope.setTags({
        appName: config.appName,
        commitHash: config.build.commitHash,
        platform: config.build.platform,
        version: config.build.version
      });
      return scope;
    }
  });

  // support for unhandled promise rejections
  window.addEventListener('unhandledrejection', (err: any) => {
    console.error(err.reason);
    Sentry.captureException(err.reason);
  });

  window.onerror = (message, url, line, column, error) => {
    const errorInfo = [message, url, line, column, error];
    console.error('unhandled error', errorInfo);
    const msg = JSON.stringify(errorInfo);
    Sentry.captureMessage(msg);
  };
}

const ngfRootOptions: NgForageOptions = {
  name: config.appName,
  size: 4900000,
  storeName: config.appName
};

const authConfig = {
  build,
  oauth2: config.oauth2,
  appName: config.appName,
  getReceivedCustomURL,
  openBrowserTab,
  closeBrowserTab,
  useRefreshToken: true,
  persistentRefreshToken: true,
  userMeEndpoint: config.userMeEndpoint,
  langLocalStorageKey: '__tolgee_currentLanguage'
} as unknown as IAuthConfig; // unknown resolves ts error due to not-material versions of angular differences.

debug('authconfig', JSON.stringify(authConfig, undefined, 2));

const socketConfig = {
  path: environment.endpoints['socket-service'],
  host: config.environmentHost,
  build,
  appName: config.appName
} as ISocketConfig;

const providers: Provider[] = [
  AuthService,
  Browser,
  BrowserGuard,
  BrowserPushService,
  Config,
  CordovaService,
  DateService,
  AuthGuard,
  { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
  PreferencesService,
  PushService,
  RestService,
  ServiceWorkerService,
  SessionEventService,
  SubscriptionService,
  TokenService,
  {
    provide: TOLGEE_INSTANCE,
    useFactory: () => {
      return Tolgee()
        .use(BackendFetch({
          prefix: aConfig.getServiceUrl('user-service') + 'i18n',
          headers: {
            'X-App-Name': aConfig.appName
          }
        }))
        .use(FormatSimple())
        .use(LanguageDetector())
        .use(LanguageStorage())
        .init({
          availableLanguages,
          defaultLanguage: 'en',
          onTranslationMissing: error => {
            debug('translation missing', error);
            return error.defaultValue || 'translation missing';
          }
        });
    }
  },
  UIStateService,
  UserService,
  VersionUpdateService,
  {
    provide: DEFAULT_CONFIG,
    useValue: ngfRootOptions
  },
  {
    provide: AUTH_CONFIG,
    useValue: authConfig
  },
  {
    provide: SOCKET_CONFIG,
    useValue: socketConfig
  }

];

if (sentryUrl) {
  providers.push({
    provide: ErrorHandler,
    useClass: SentryErrorHandler
  });
}

debug('socketConfig', socketConfig);
@NgModule({
  bootstrap: [
    IndexComponent
  ],
  declarations: [
    IndexComponent,
    SupportedBrowsersComponent,
    VersionUpdateComponent,
    InfoMessageComponent
  ],
  imports: [
    AuthModule.forRoot(authConfig),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SocketModule.forRoot(socketConfig),
    NgxTolgeeModule,
    appRoutes
  ],
  providers
})
export class AppModule {
  public constructor(translateService: TranslateService, private userService: UserService) {
    debug('appmodule constructor');
    translateService.tolgee.run();
  }
}

