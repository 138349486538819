<div class="supported-browsers below-logo">
  <div class="title text-center">
    <span t key="supportedBrowsers.component_Supported web browsers" default="Supported web browsers">
    Supported web browsers</span></div>
  <div>
    <p>
      <span t key="supportedBrowsers.component_To maintain security, it is important to be using a recent version of a
      modern browser" default="To maintain security, it is important to be using a recent version of a modern browser">
      To maintain security, it is important to be using a recent version of a modern browser</span>.
    </p>
    <p *ngIf="!browser.isValid">
      <span t key="supportedBrowsers.component_You are using an unsupported web browser" default="You are using an unsupported web browser">
      You are using an unsupported web browser</span>.</p>
    <p>
      <span t key="supportedBrowsers.component_You are using" default="You are using">
      You are using</span> {{ browser.info.name }} {{ browser.info.version }}.</p>
    <p>
      <span t key="supportedBrowsers.component_These are the minimum versions of popular browsers supported" default="These are the minimum versions of popular browsers supported">
      These are the minimum versions of popular browsers supported</span>:</p>
    <ul>
      <li *ngFor="let browser of browser.supportedBrowsers">
        {{ browser.name }} {{ browser.version }}
      </li>
    </ul>
    <p>
      <span t key="supportedBrowsers.component_Internet Explorer versions 10 or older or Safari on Windows is NOT
      supported" default="Internet Explorer versions 10 or older or Safari on Windows is NOT
      supported">
      Internet Explorer versions 10 or older or Safari on Windows is NOT supported</span>
    </p>
    <p>
      <span t key="supportedBrowsers.component_To install a supported browser or to update your existing browser, contact
      your IT team or visit" default="To install a supported browser or to update your existing browser, contact
      your IT team or visit">
      To install a supported browser or to update your existing browser, contact your IT team or visit</span>
      <a href="https://updatemybrowser.org/">updatemybrowser.org</a>.
    </p>
  </div>
</div>

<style>
  /* hack because we can't easily override html element styles conditionally */
  html {
    overflow-y: auto !important;
  }
  body {
    overflow: visible !important;
  }
</style>
