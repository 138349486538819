import { Injectable } from '@angular/core';
import * as Debug from 'debug';

import { Config } from '../framework/config';

const debug = Debug('sst:serviceworker.service');
@Injectable({
  providedIn: 'root'
})
export class ServiceWorkerService {
  public supported: boolean = false;
  public swReg: ServiceWorkerRegistration;

  constructor(private config: Config) {
    if (window['cordova']) {
      this.supported = false;
      debug('service workers are not supported in cordova');
      return;
    }
    if ('serviceWorker' in navigator) {
      debug('Service Worker is supported');
      this.supported = true;
      this.register();
    } else {
      debug('ServiceWorker is not supported on this platform');
    }
  }

  public getRegistration(): Promise<ServiceWorkerRegistration> {
    return navigator.serviceWorker.ready;
  }

  public sendMessageToSW = message => {
    return navigator.serviceWorker.ready
      .then(registration => {
        return new Promise((resolve, reject) => {
          const messageChannel = new MessageChannel();
          messageChannel.port1.onmessage = e => {
            if (e.data.error) {
              reject(e.data.error);
            } else {
              resolve(e.data);
            }
          };

          const controller = registration.active;
          if (controller) {
            controller.postMessage(message, [messageChannel.port2]);
          } else {
            debug('no serviceworker.controller to send message to: ', registration.active, navigator.serviceWorker);

            // i18n this string is not displayed to user
            reject(new Error('no serviceworker.controller'));
          }
        });
      });
  };

  private onControllerChange = () => {
    debug('service worker has changed, or has been installed the first time');
  };

  private onUpdateFound = () => {
    debug('service worker update found');
    const installingWorker = this.swReg.installing;

    installingWorker.addEventListener('statechange', () => {
      switch (installingWorker.state) {
      case 'installed':

        if (navigator.serviceWorker.controller) {
          // new worker has finished
          debug('new worker finished activating');

          // window.location.href = './index.html';
        } else {
          debug('new worker not yet finished activating');
        }

        break;
      case 'redundant':
        console.error('The installing service worker became redundant');
        break;
      }
    });
  };

  private register(): Promise<ServiceWorkerRegistration> {
    return navigator.serviceWorker.register(`sw.js?v=${this.config.build.version}`)
      .then((/* registration*/) => {
        // don't use registration obj yet, use it when
        // sw is ready
        return navigator.serviceWorker.ready;
      }).then(registration => {
        this.swReg = registration;
        registration.addEventListener('controllerchange', this.onControllerChange);
        registration.addEventListener('updatefound', this.onUpdateFound);

        debug('service worker ready', registration);
        return registration;
      });
  }
}
